import _ from 'lodash';
import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { produce } from 'immer';
import { Affix } from 'antd';
import {
	reduxForm,
	initialize as initializeFormAction,
	reset as resetFormAction,
	InjectedFormProps,
	SubmissionError,
	FormErrors,
} from 'redux-form';
import cn from 'classnames';

import type { StoryModel } from 'types/story';
import type { AdminReducerState } from 'admin/reducers';
import type { VoluntaryUpdateList } from 'admin/utils/story-migration-adapter';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';

import t from 'utils/translate';
import { gaRegExp } from 'utils/regexp';
import { StoryFacade } from 'utils/facades/story-facade';
import IntegrationsFacade, { INTEGRATIONS_NAMES } from 'utils/facades/integrations-facade';
import { selectCurrentVersion } from 'admin/reducers/version-reducer';
import { selectEditableStory } from 'admin/reducers/story-editor/selectors';
import { postGallery } from 'admin/actions/story/gallery';
import { updateStoryUrl } from 'admin/actions/story/update-story-url';
import { putStorycardsDomain } from 'admin/actions/story/storcards-domain';
import { setDefaultStoryDomain } from 'admin/actions/story/set-default-story-domain';
import { updateEditableStory } from 'admin/actions/story/update-editable-story';
import Text from 'admin/components/common/Text';
import { Modal } from 'admin/components/common/Modal';
import Button from 'admin/components/common/Button';
import { MediaField } from 'admin/components/common/Form';
import { FORM_MODEL, MODAL } from 'admin/constants/common';
import { toast } from 'admin/components/common/MessageContainer/toast';
import { errorsSelector } from 'admin/components/common/Form/Errors';
import AdminError from 'admin/components/common/ErrorBoundary/AdminError';
import { isSuperUserSelector, selectActiveOrganization } from 'admin/reducers/user/selectors';
import BackButton from 'admin/components/common/Modal/BackButton/BackButton';
import { validateGPTIntegration } from 'admin/components/common/Intregrations/GooglePublisherTag';

import { SettingsTabsGeneralProps } from './types';
import Integrations from './Integrations';
import Settings from './Settings';
import Seo from './Seo';
import Restrictions from './Restrictions';
import Activity from './Activity';
import Fonts from './Fonts';
import Updates from './Updates';
import GDPR from './GDPR';
import { fields, VIEW_MAP } from './constants';

import css from './StorySettingsModal.scss';

const contentViewMap = {
	[VIEW_MAP.GENERAL]: Settings,
	[VIEW_MAP.SEO]: Seo,
	[VIEW_MAP.FONTS]: Fonts,
	[VIEW_MAP.INTEGRATION]: Integrations,
	[VIEW_MAP.RESTRICTIONS]: Restrictions,
	[VIEW_MAP.GDPR]: GDPR,
	[VIEW_MAP.ACTIVITY]: Activity,
	[VIEW_MAP.UPDATES]: Updates,
} as const;

export type StorySettingsModalData =
	| {
			view?: Exclude<(typeof VIEW_MAP)[keyof typeof VIEW_MAP], typeof VIEW_MAP.UPDATES>;
	  }
	| {
			view: typeof VIEW_MAP.UPDATES;
			voluntaryUpdateList: VoluntaryUpdateList;
	  };

type OwnProps = ModalManagerProvidedProps<MODAL.STORY_SETTINGS> & RouteComponentProps;

const initialState = {
	currentView: VIEW_MAP.GENERAL,
};

type PureProps = ConnectedProps<typeof connector> & OwnProps;
type FormProp = InjectedFormProps<StoryModel, PureProps>;
type Props = PureProps & FormProp;

type State = {
	currentView: (typeof VIEW_MAP)[keyof typeof VIEW_MAP];
};

class StorySettingsModal extends Component<Props, State> {
	isFormSuccessSubmit = false;

	contentRef = React.createRef<HTMLDivElement>();

	state: State = {
		...initialState,
		currentView: this.props.data.view ?? initialState.currentView,
	};

	componentDidMount() {
		this.initForm();
	}

	componentDidUpdate(prevProps: Props) {
		const { submitting, story, submitFailed } = this.props;

		// prevent form init in case of there is any submit errors, to be able to display these errors in UI
		this.isFormSuccessSubmit = !submitFailed;

		// all these things only for reinitialize the storyEditor form with updated values
		if (prevProps.story !== story) {
			this.isFormSuccessSubmit = true;
		}

		if (this.isFormSuccessSubmit && prevProps.submitting && !submitting) {
			this.initForm();
			this.isFormSuccessSubmit = false;
		}

		if (this.props.location !== prevProps.location) {
			this.props.close();
			this.onModalClose();
		}
	}

	get formInfo(): SettingsTabsGeneralProps['formInfo'] {
		return {
			dirty: this.props.dirty,
			submitting: this.props.submitting,
			asyncValidating: !!this.props.asyncValidating,
		};
	}

	get isFormButtonsAvailable(): boolean {
		return (
			this.state.currentView === VIEW_MAP.GENERAL ||
			this.state.currentView === VIEW_MAP.RESTRICTIONS ||
			this.state.currentView === VIEW_MAP.SEO ||
			this.state.currentView === VIEW_MAP.FONTS ||
			this.state.currentView === VIEW_MAP.GDPR
		);
	}

	initForm = () => {
		this.props.initializeForm(
			FORM_MODEL.EDIT_STORY,
			produce(this.props.story, draft => {
				const { version } = this.props;
				const currentFavicon = _.get(draft, fields.favicon.name(version));

				if (!currentFavicon && this.props.organization?.favicon) {
					_.set(draft, fields.favicon.name(version), this.props.organization.favicon);
				}
			})
		);
	};

	onModalClose = () => {
		if (this.props.dirty && !this.props.submitSucceeded) {
			this.props.resetForm(FORM_MODEL.EDIT_STORY);
		}
	};

	onMenuItemClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const contentRef = this.contentRef.current;

		if (this.props.invalid) {
			const errors = contentRef?.querySelector(errorsSelector);
			if (errors) errors.scrollIntoView({ block: 'center', behavior: 'smooth' });
			else toast.error('Form is not valid, please check field values.', 3);
			return;
		}

		const view = e.currentTarget.dataset?.id as State['currentView'] | undefined;

		contentRef?.scrollTo({ top: 0, behavior: 'smooth' });

		this.setState({ currentView: view || VIEW_MAP.GENERAL });
	};

	onFormSubmit = async (values: StoryModel) => {
		const { version, story } = this.props;
		const allFields = [
			// story fields names
			...Object.values(fields).map(field =>
				typeof field.name === 'function' ? field.name(version) : field.name
			),
			// integration field names
			...Object.values(IntegrationsFacade.getFields(version)).flatMap(group =>
				Object.values(group).map(field => field.name)
			),
		] satisfies string[];

		const submissionErrors: FormErrors<typeof this.props.formValues> = {};
		const storyId = story.id;

		// Apply updates
		const data = produce(story, draft => {
			allFields.forEach(path => {
				let nextValue = _.get(values, path);

				if (path === fields.domainId.name()) {
					const domainId = parseInt(nextValue, 10);
					nextValue = Number.isNaN(domainId) ? null : domainId;
				}

				if (path === fields.favicon.name(version)) {
					const organizationFavicon = this.props.organization?.favicon;

					if (nextValue === organizationFavicon) {
						nextValue = '';
					}
				}

				if (nextValue === '' || nextValue === undefined) _.unset(draft, path);
				else _.set(draft, path, nextValue);
			});

			// disabled oncePerUser if domains is not custom
			if (_.get(draft, fields.oncePerUser.name(version)) && !new StoryFacade(draft).canUseThirdPartyCookies()) {
				_.set(draft, fields.oncePerUser.name(version), false);
			}
		});

		// Validate default domain
		const isDefaultDomainChanged = data.domainId !== story.domainId;
		if (isDefaultDomainChanged) {
			const payload = {
				storyId,
				domainId: data.domainId,
				useOrgDomain: true,
			};

			if (data.domainId === null) {
				payload.domainId = null;
				payload.useOrgDomain = false;
			} else if (data.domainId === this.props.organization?.domainId) {
				payload.domainId = null;
				payload.useOrgDomain = true;
			}
			const response = await this.props.setDefaultStoryDomain(payload);

			if (response.errors) {
				throw new AdminError(response.errors[0]?.message || '"domainId" is invalid', { duration: 2 });
			}
		}

		// Validate clientStoryId
		const isClientStoryIdChanged = data.clientStoryId !== story.clientStoryId;
		if (isClientStoryIdChanged) {
			const response = await this.props.updateStoryUrl({ storyId, clientStoryId: data.clientStoryId });
			if (!response?.success || response?.errors) {
				submissionErrors.clientStoryId =
					response?.errors?.[0]?.statusCode === 400
						? 'URL name is already in use'
						: 'Failed. Please, try again';
			}
		}

		const storycardsDomainPath = `${fields.storycardsDomain.name()}.domain`;
		const storycardsDomainNext = _.get(data, storycardsDomainPath);
		const storycardsDomainPrev = _.get(story, storycardsDomainPath);
		if (storycardsDomainNext && storycardsDomainNext !== storycardsDomainPrev) {
			const response = await this.props.putStorycardsDomain({ storyId, domain: storycardsDomainNext });
			if (!response.success) _.set(submissionErrors, storycardsDomainPath, 'Failed');
		}

		if (Object.keys(submissionErrors).length) {
			throw new SubmissionError(submissionErrors);
		}

		return this.props.updateEditableStory({ data, entire: true });
	};

	onDiscardClick = () => {
		this.props.resetForm(FORM_MODEL.EDIT_STORY);
	};

	renderMenuTitle = (key: string) => (
		<Text
			tag="span"
			size={Text.size.subheading}
			weight={Text.weight.normal}
			compact
			text={t(`story.settings.navigation.${key}`)}
		/>
	);

	renderHeader = () => {
		const { story } = this.props;

		return (
			<div className={css.header}>
				<div className={css.col}>
					<BackButton
						onClick={() => {
							this.props.close();
							this.onModalClose();
						}}
					/>
				</div>
				<div className={css.col}>
					<Text
						tag="span"
						size={Text.size.paragraph}
						weight={Text.weight.normal}
						className={css.name}
						compact
						text={`${(story.name ?? '').slice(0, 45)}${_.size(story.name) > 45 ? '...' : ''} / `}
					/>
					<Text
						tag="span"
						size={Text.size.paragraph}
						weight={Text.weight.bold}
						compact
						text={t('story.settings.title')}
					/>
				</div>
			</div>
		);
	};

	renderMenu = () => {
		const { type: storyType } = this.props.story;
		const { currentView } = this.state;
		const exclusionList: (typeof VIEW_MAP)[keyof typeof VIEW_MAP][] =
			storyType === 'widget' ? [VIEW_MAP.SEO, VIEW_MAP.RESTRICTIONS] : [];

		return (
			<div className={css.menu}>
				<div className={css.list}>
					{_.map(
						Object.values(VIEW_MAP).filter(value => !exclusionList.includes(value)),
						(value: string) => (
							<button
								className={cn(css.menuItem, currentView === value && css.active)}
								key={`menu-item-${value}`}
								data-id={value}
								onClick={this.onMenuItemClick}
								type="button"
							>
								{this.renderMenuTitle(value)}
							</button>
						)
					)}
				</div>
			</div>
		);
	};

	renderContent = () => {
		const { currentView } = this.state;
		const { handleSubmit, submitting, formValues, dirty, story, version, asyncValidating, invalid } = this.props;

		const Content = contentViewMap[currentView] as React.ElementType<SettingsTabsGeneralProps>;

		if (Content) {
			return (
				<div className={css.content} ref={this.contentRef}>
					<form onSubmit={handleSubmit(this.onFormSubmit)}>
						<Content
							formValues={formValues}
							postGallery={this.props.postGallery}
							version={version}
							story={story}
							fields={fields}
							isSuperUser={this.props.isSuperUser}
							modal={this.props.modal}
							updateEditableStory={this.props.updateEditableStory}
							plan={this.props.organization?.plan}
							formInfo={currentView === 'integrations' ? this.formInfo : null}
						/>

						{this.isFormButtonsAvailable && (
							<Affix offsetBottom={0}>
								<div className={css.buttons}>
									<Button
										theme="dark"
										loading={submitting}
										disabled={!!asyncValidating || !dirty || submitting || invalid}
										smallText
										type="submit"
									>
										{t('story.settings.update')}
									</Button>
									<Button
										theme="dark"
										view="empty"
										disabled={!!asyncValidating || !dirty || submitting}
										smallText
										onClick={this.onDiscardClick}
									>
										{t('story.settings.discard')}
									</Button>
								</div>
							</Affix>
						)}
					</form>
				</div>
			);
		}

		return null;
	};

	render() {
		const { open } = this.props;

		if (!open) return null;

		return (
			<Modal
				theme="dark"
				useThemeClass={false}
				open={open}
				title={null}
				centered={false}
				destroyOnClose
				width={parseInt(css.modalWidth, 10)}
				className={css.modal}
				maskColor="black"
				onCancel={this.onModalClose}
				transitionName=""
			>
				{this.renderHeader()}
				{this.renderMenu()}
				{this.renderContent()}
			</Modal>
		);
	}
}

interface IErrors {
	[key: string]: string | IErrors;
}

const SettingsForm = reduxForm<StoryModel, PureProps>({
	destroyOnUnmount: false,
	validate: (values, props) => {
		const errors: IErrors = {};

		if (Object.keys(values).length === 0) {
			return errors;
		}

		const integrationFields = IntegrationsFacade.getFields(props.version);
		const gaValue = _.get(values, integrationFields.ga.gaUniversal.name);
		const ga4Value = _.get(values, integrationFields.ga.ga4.name);

		const isOutbrainVisible = _.get(values, integrationFields.outbrain.visible.name);

		const settingsPath = `storyVersions.${props.version}.settings`;
		const path = {
			favicon: `${settingsPath}.seo.favicon`,
			webclip: `${settingsPath}.seo.webclip`,
			shareImg: `${settingsPath}.share.image`,
			invalidGaId: `common.integrations.views.${INTEGRATIONS_NAMES.GA}.invalidGaId`,
		};

		const favicon = _.get(values, path.favicon);
		const webclip = _.get(values, path.webclip);
		const shareImg = _.get(values, path.shareImg);

		if (!values.name) {
			errors.name = t('story.settings.storyNameError');
		}

		const [, ...slug] = values.clientStoryId.split('/');
		if (!/^[A-Za-z0-9_-]+(?:[-_][A-Za-z0-9-_]+)*$/g.test(slug.join(''))) {
			_.set(
				errors,
				fields.clientStoryId.name(),
				`Invalid format. Cannot be empty or contain forbidden characters`
			);
		}

		if (gaValue && !gaRegExp.test(gaValue)) {
			_.set(errors, integrationFields.ga.gaUniversal.name, t(path.invalidGaId));
		}

		if (ga4Value && !gaRegExp.test(ga4Value)) {
			_.set(errors, integrationFields.ga.ga4.name, t(path.invalidGaId));
		}

		if (isOutbrainVisible) {
			if (!_.get(values, integrationFields.outbrain.desktopWidgetId.name)) {
				_.set(errors, integrationFields.outbrain.desktopWidgetId.name, 'required');
			}
			if (!_.get(values, integrationFields.outbrain.mobileWidgetId.name)) {
				_.set(errors, integrationFields.outbrain.mobileWidgetId.name, 'required');
			}
		}

		if (favicon === MediaField.errors.FILE_SIZE_LIMIT) {
			_.set(errors, path.favicon, MediaField.errorsMsg[MediaField.errors.FILE_SIZE_LIMIT]);
		} else if (favicon === MediaField.errors.FILE_WIDTH_HEIGHT_LARGE) {
			_.set(errors, path.favicon, MediaField.errorsMsg[MediaField.errors.FILE_WIDTH_HEIGHT_LARGE]);
		} else if (favicon === MediaField.errors.FILE_WIDTH_HEIGHT_SMALL) {
			_.set(errors, path.favicon, MediaField.errorsMsg[MediaField.errors.FILE_WIDTH_HEIGHT_SMALL]);
		}

		if (webclip === MediaField.errors.FILE_SIZE_LIMIT) {
			_.set(errors, path.webclip, MediaField.errorsMsg[MediaField.errors.FILE_SIZE_LIMIT]);
		} else if (webclip === MediaField.errors.FILE_WIDTH_HEIGHT_LARGE) {
			_.set(errors, path.webclip, MediaField.errorsMsg[MediaField.errors.FILE_WIDTH_HEIGHT_LARGE]);
		} else if (webclip === MediaField.errors.FILE_WIDTH_HEIGHT_SMALL) {
			_.set(errors, path.webclip, MediaField.errorsMsg[MediaField.errors.FILE_WIDTH_HEIGHT_SMALL]);
		}

		if (shareImg === MediaField.errors.FILE_SIZE_LIMIT) {
			_.set(errors, path.shareImg, MediaField.errorsMsg[MediaField.errors.FILE_SIZE_LIMIT]);
		} else if (shareImg === MediaField.errors.FILE_WIDTH_HEIGHT_LARGE) {
			_.set(errors, path.shareImg, MediaField.errorsMsg[MediaField.errors.FILE_WIDTH_HEIGHT_LARGE]);
		} else if (shareImg === MediaField.errors.FILE_WIDTH_HEIGHT_SMALL) {
			_.set(errors, path.shareImg, MediaField.errorsMsg[MediaField.errors.FILE_WIDTH_HEIGHT_SMALL]);
		} else if (shareImg === MediaField.errors.UPLOAD_FAILED) {
			_.set(errors, path.shareImg, MediaField.errorsMsg[MediaField.errors.UPLOAD_FAILED]);
		}

		const gptIntegrationErrors = validateGPTIntegration({ form: values, version: props.version });
		gptIntegrationErrors.forEach(item => _.set(errors, item.path, item.value));

		return errors;
	},
	onSubmitFail(errors, dispatch, submitError, props) {
		return errors;
	},
	onSubmitSuccess() {
		toast.success("Saved! It's time to publish your story.", 3.5);
	},
	form: FORM_MODEL.EDIT_STORY,
})(StorySettingsModal);

const mapStateToProps = (state: AdminReducerState) => ({
	story: selectEditableStory(state)!,
	formValues: state.form?.storyEditor?.values as StoryModel,
	version: selectCurrentVersion(state),
	isSuperUser: isSuperUserSelector(state),
	modal: state.modal,
	organization: selectActiveOrganization(state),
});

const mapDispatchToProps = {
	postGallery,
	updateEditableStory,
	initializeForm: initializeFormAction,
	resetForm: resetFormAction,
	setDefaultStoryDomain,
	updateStoryUrl,
	putStorycardsDomain,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(SettingsForm));
