import React, { useContext, useState } from 'react';
import { Steps, ConfigProvider, ThemeConfig } from 'antd';
import cms from 'common/utils/cms';
import { MODAL } from 'admin/constants/common';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Select, Hint } from 'admin/components/common/Form';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { Context as ThemeContext } from 'admin/components/common/Theme';
import { Column, Grid } from 'admin/components/common/Grid';
import css from './CmsPropBindModalData.scss';

const collectionOptions = cms.collections.map(c => ({ value: c.collectionId, label: c.name }));

const getCollectionFieldOptions = (collectionId: string) => {
	const collectionFields = cms.collections.find(c => c.collectionId === collectionId)?.fields;
	if (!collectionFields) return [];
	return collectionFields.map(f => ({ value: f.dataIndex, label: f.title }));
};

const getCollectionDataOptions = (collectionId: string) => {
	const collectionData = cms.collections.find(c => c.collectionId === collectionId)?.data;
	if (!collectionData) return [];
	return collectionData.map((d, i) => ({ value: `${i}`, label: `${i}` }));
};

const antdTheme: ThemeConfig = {
	token: {
		colorText: '#fff',
		colorTextDescription: 'rgba(255, 255, 255, 0.45)',
		colorTextDisabled: 'blue',
		colorTextQuaternary: 'red',
		colorBorderSecondary: 'rgba(255, 255, 255, 0.18)',
	},
};

type ContentProps = ModalManagerProvidedProps<MODAL.CMS_PROP_BIND_MODAL>;

const Content: React.FC<ContentProps> = props => {
	const { theme } = useContext(ThemeContext);
	const [property, setProperty] = useState(props.data.properties[0].value);
	const [collection, setCollection] = useState(props.data.collectionId);
	const collectionFieldOptions = getCollectionFieldOptions(collection ?? '');
	const [collectionField, setCollectionField] = useState(collectionFieldOptions[0]?.value);
	const collectionDataOptions = getCollectionDataOptions(collection ?? '');
	const [collectionData, setCollectionData] = useState(`${collectionDataOptions[0]?.value ?? ''}`);
	const [currentStep, setCurrentStep] = useState(0);

	const onChange = (value: string, callback: (v: string) => void) => {
		callback(value);
		// setCurrentStep(s => s + 1);
	};

	const steps = [
		{
			title: 'Component property',
			description: props.data.properties.find(p => p.value === property)?.label,
			isValid: !property,
			content: (
				<Select
					// label={{ children: 'Select component property' }}
					options={props.data.properties}
					value={property}
					theme={theme}
					eventListeners={{ onChange: v => onChange(v, setProperty) }}
					// menuPlacement="top"
					// maxMenuHeight={200}
				/>
			),
		},
		{
			title: 'Collection',
			description: collectionOptions.find(p => p.value === collection)?.label,
			isValid: !collection,
			content: (
				<Select
					options={collectionOptions}
					value={collection}
					theme={theme}
					eventListeners={{ onChange: v => onChange(v, setCollection) }}
				/>
			),
		},
		{
			title: 'Collection column',
			description: collectionFieldOptions.find(p => p.value === collectionField)?.label,
			isValid: !collectionField,
			content: (
				<Select
					options={collectionFieldOptions}
					value={collectionField}
					theme={theme}
					eventListeners={{ onChange: v => onChange(v, setCollectionField) }}
				/>
			),
		},
		{
			title: 'Collection row [Optional]',
			description: collectionDataOptions.find(p => p.value === collectionData)?.label,
			isValid: !collectionData,
			content: (
				<>
					<Select
						options={collectionDataOptions}
						value={collectionData}
						theme={theme}
						eventListeners={{ onChange: setCollectionData }}
					/>
					<br />
					<Hint
						theme="dark"
						text="Skip this step if you'd like to repeat from the collection without choosing a specific
						 data point"
					/>
				</>
			),
		},
	];

	const lastStepIndex = steps.length - 1;

	return (
		<>
			<ModalBody>
				<Grid columns="14" gap="large">
					<Column colSpan="6">{steps[currentStep].content}</Column>

					<Column colSpan="8" style={{ paddingTop: 16 }}>
						<ConfigProvider theme={antdTheme}>
							<Steps
								size="small"
								direction="vertical"
								current={currentStep}
								items={steps.map((s, i) => ({
									key: `step-${s.title}`,
									title: s.title,
									description: i < currentStep ? s.description : undefined,
									disabled: i > currentStep,
								}))}
								onChange={setCurrentStep}
							/>
						</ConfigProvider>
					</Column>
				</Grid>
			</ModalBody>

			<ModalFooter>
				<Column justifyContent="flex-start">
					{currentStep >= lastStepIndex - 1 && (
						<Button
							view="primary"
							disabled={!collection || !collectionField || !property}
							onClick={() => {
								props.data.onSave({
									property,
									collectionId: collection!,
									collectionField,
									collectionData,
								});
								props.close();
							}}
						>
							Save
						</Button>
					)}

					{currentStep < lastStepIndex && (
						<Button
							view="secondary"
							color="primary"
							disabled={steps[currentStep].isValid}
							onClick={() => setCurrentStep(s => s + 1)}
						>
							Next
						</Button>
					)}

					<Button view="empty" onClick={props.close}>
						Cancel
					</Button>
				</Column>
			</ModalFooter>
		</>
	);
};

const modalTitle: Record<CmsPropBindModalData['view'], string> = {
	add: 'Link property to collection',
};

const viewMap: Record<CmsPropBindModalData['view'], typeof Content> = {
	add: Content,
};

export type CmsPropBindModalData = {
	view: 'add';
	properties: { value: string; label: string }[];
	collectionId?: string;
	onSave: (props: {
		collectionId: string;
		collectionField: string;
		property: string;
		collectionData?: string;
	}) => void;
};

type Props = ModalManagerProvidedProps<MODAL.CMS_PROP_BIND_MODAL>;

const CmsPropBindModal: React.FC<Props> = props => {
	const ViewComponent = viewMap[props.data.view];
	const title = modalTitle[props.data.view];

	return (
		<Modal
			theme="dark"
			maskColor="black"
			open={props.open}
			destroyOnClose
			width={740}
			className={css.modal}
			transitionName=""
			title={title ? <Text size={Text.size.subtitle} weight={Text.weight.semibold} compact text={title} /> : null}
		>
			<ViewComponent {...props} />
		</Modal>
	);
};

export default CmsPropBindModal;
