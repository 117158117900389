import { get, set, unset } from 'lodash';
import { Draft, produce } from 'immer';
import { captureMessage } from '@sentry/react';
import type { RouteComponentProps } from 'react-router-dom';
import {
	BBMetaType,
	BBModel,
	BBStylesProp,
	BBSymbolLink,
	CardData,
	StoryModel,
	StorySettingsType,
	StoryVersionType,
	WithStateAndPlatform,
	BBModelTextChild,
} from 'types/story';
import cms from 'common/utils/cms';
import { CARD_TYPE, COMPONENT_TYPE, SYSTEM_FONTS, STORY_SETTINGS_COMPONENTS } from 'common/constants';
import { adminLog } from 'utils/helpers';
import { isFieldBlock } from 'utils/blocks/misc';
import { StoryFacade } from 'utils/facades/story-facade';
import { cutURLMethod, getSourceType, replaceUrlHostname } from 'utils/assets';
import { componentWalk } from 'utils/blocks/component-walk';
import { componentWalkFull } from 'utils/blocks/component-walk-full';
import { NavigationFacade, CountType } from 'utils/facades/navigation-card-facade';
import { NOT_FOUND, STORIES_PAGE } from 'admin/constants/routes';
import { GET_STORY } from 'admin/constants/actions';
import { createThunkAction } from 'admin/actions/helpers';
import { getStory as getStoryEndpoint } from 'admin/resources';
import textCssVariables from 'client/components/common/BuildingBlocks/Text/variables.scss';

const IS_FILTER_VERSIONS = false;

interface GetStoryParams {
	storyId: string;
	history?: RouteComponentProps['history'];
}

/**
 * Get story by id
 */
export const getStory = createThunkAction<StoryModel, GetStoryParams>({
	type: GET_STORY,
	payloadCreator: async params => {
		const response = await getStoryEndpoint.params({ storyId: params.storyId }).send();
		const story: StoryModel = response.body.result;
		let result = story;

		if (IS_FILTER_VERSIONS && story) {
			result = filterStoryVersions(story);
		}

		result = produce(result, draftStory => {
			try {
				draftStory.storyVersions[StoryFacade.VERSIONS.latest] = processStory(new StoryFacade(result));
			} catch (e) {
				console.error(e);
				captureMessage('getStory:processStory() is failed');
			}
		});

		return { ...response.body, result };
	},
	onError: ({ params, error }) => {
		if (params.history) {
			if (error?.status === 404) params.history.push(NOT_FOUND);
			else if (error?.status === 403) params.history.push(STORIES_PAGE);
		}
	},
});

//  pick limited versions count to not overload "redux"
function filterStoryVersions(story: StoryModel, limit = 2): StoryModel {
	const storyVersions = {};
	const latest = story.storyVersions[StoryFacade.VERSIONS.latest];
	const published = story.storyVersions[StoryFacade.VERSIONS.published];

	[StoryFacade.VERSIONS.archived, StoryFacade.VERSIONS.unpublished].forEach(version => {
		const result = Object.values(story.storyVersions)
			.filter(ver => ver.version.startsWith(version))
			.sort((a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt))
			.slice(0, limit)
			.reduce((acc, cur) => {
				acc[cur.version] = cur;
				return acc;
			}, {});

		if (Object.keys(result)) {
			Object.assign(storyVersions, result);
		}
	});

	if (latest) {
		Object.assign(storyVersions, { latest });
	}

	if (published) {
		Object.assign(storyVersions, { published });
	}

	return { ...story, storyVersions };
}

enum StoryDoctorError {
	CardIdNotUnique = 'CardIdNotUnique',
	InvalidCardData = 'InvalidCardData',
	ComponentIdNotUnique = 'ComponentIdNotUnique',
	InvalidComponentData = 'InvalidComponentData',
}

/**
 * Class for detecting and fixing unexpected critical errors in the Story data structure.
 */
class StoryDoctor {
	log = adminLog.extend('StoryDoctor');
	// list or errors
	private errors: Array<{ type: StoryDoctorError; path: string; message: string }> = [];
	// set of unique card id
	private cardsIdSet = new Set<string>();
	// set of unique instance id
	private symbolInstanceIdSet = new Set<string>();

	private reportError = (type: StoryDoctorError, path: string, message: string) => {
		captureMessage('StoryDoctor.reportError', {
			level: 'error',
			contexts: { data: { type, path, message } },
		});

		this.errors.push({ type, path, message });
	};

	/**
	 * Checking the health of the card
	 * @return - false for invalid and true for a valid component
	 */
	checkCard = ({ card, path }: { card: CardData; path: string }) => {
		if (card._id === undefined || !card.type || !card.elements || !card.events) {
			this.reportError(StoryDoctorError.InvalidCardData, path, `Card data is invalid.\nPath: "${path}"`);
			return false;
		}

		if (this.cardsIdSet.has(card._id)) {
			const errMsg = `Card with id "${card._id}" already exists.\nPath: "${path}"`;
			this.reportError(StoryDoctorError.CardIdNotUnique, path, errMsg);
			return false;
		}

		this.cardsIdSet.add(card._id);

		return true;
	};

	/**
	 * Checking the health of the component
	 * @return - false for invalid and true for a valid component
	 */
	checkComponent = ({ component, path }: { component: BBModel; path: string }) => {
		if (component._id === undefined || !component.type || !component.children || !component.uiConfig) {
			const errMsg = `Missing component data\n"${JSON.stringify(component, null, 3)}"\nPath: "${path}"`;
			this.reportError(StoryDoctorError.InvalidComponentData, path, errMsg);
			return false;
		}

		const instanceId = component.symbol?.instanceId;
		const isInstanceRoot = !component.symbol?.childId;
		if (instanceId && isInstanceRoot) {
			if (this.symbolInstanceIdSet.has(instanceId)) {
				const errMsg = `Instance with id "${instanceId} already exists".\nPath: "${path}"`;
				this.reportError(StoryDoctorError.ComponentIdNotUnique, path, errMsg);
				return false;
			}
			this.symbolInstanceIdSet.add(instanceId);
		}

		// todo: check for uniqueness of story.elements children id
		//       Do this after it's done: https://app.asana.com/0/1199600378547903/1203019520740128/f
		// todo: check for uniqueness of regular element id
		//       Do this after it's done: https://app.asana.com/0/1199600378547903/1203019520740128/f
		// todo: check for uniqueness of symbol children id
		//       Do this after it's done: https://app.asana.com/0/1201825339269437/1205723387702818/f [completed]
		//       Make sure that you found the way how to handle existed stories where duplicated id took a place

		return true;
	};

	/**
	 * Remove invalid data by the `error.path` at story
	 */
	heal = (story: StoryVersionType) => {
		if (this.errors.length === 0) return;

		this.log('errors: ', this.errors);

		/**
		 * `reverse()` the errors array to handle removals while preserving original error indices,
		 * to safely remove errors from the end to the beginning while keeping track
		 * of their original positions, ensuring that each error is correctly
		 * addressed and not skipped during removal.
		 */
		this.errors.reverse().forEach((error, i, arr) => {
			// const msg = `${i + 1}/${arr.length} Critical error: "${error.type}". Resolve? \n\n${error.message}\n\n`;
			// if (window.confirm(msg)) {
			const parts = error.path.split('.');
			const itemIndex = Number(parts.pop());
			const itemsArrayPath = parts.join('.');
			const itemsArray = get(story, itemsArrayPath);
			if (Array.isArray(itemsArray) && Number.isInteger(itemIndex)) {
				itemsArray.splice(itemIndex, 1);
			}
			// }
		});
	};
}

interface GatherAnswerDataProps {
	component: BBModel;
	path: string;
	index: number;
	cardId: string;
}

interface AddAnswerDataAtStorySettingsProps {
	cardId: string;
	cardType: CardData['type'];
	draftSettings: Draft<StorySettingsType>;
}

/**
 * Collects data for each answer and stores it in story settings.
 */
class AnswerDataCollector {
	private currentAnswer: null | { id: string; path: string } = null;
	public answersMap = new Map<
		string,
		{
			id: string;
			path: string;
			index: number;
			name: string;
			text: Array<{ text: string; size: string }>;
			cardId: string;
		}
	>();

	gatherAnswerData({ component, path, index, cardId }: GatherAnswerDataProps) {
		if (component.type === COMPONENT_TYPE.ANSWER) {
			this.currentAnswer = { id: component._id, path };
			this.answersMap.set(this.currentAnswer.id, {
				...this.currentAnswer,
				name: component.uiConfig.editorProps.name,
				text: [],
				index,
				cardId,
			});
		} else if (this.currentAnswer) {
			const isAnswerChild = path.startsWith(this.currentAnswer.path);
			const currentAnswerData = this.answersMap.get(this.currentAnswer.id);
			if (isAnswerChild && currentAnswerData && component.type === COMPONENT_TYPE.TEXT) {
				// collect text data
				const text = (component.children as BBModelTextChild).defaultState.desktop;
				const styles = component.uiConfig.componentProps.styles as WithStateAndPlatform<BBStylesProp>;
				const size = styles.defaultState.desktop.fontSize?.toString() || textCssVariables.bbTextDefaultFontSize;
				this.answersMap.set(currentAnswerData.id, {
					...currentAnswerData,
					text: [...currentAnswerData.text, { text, size }],
				});
			} else if (!isAnswerChild) {
				// Clear current answer
				this.currentAnswer = null;
			}
		}
	}

	addAnswerDataAtStorySettings({ draftSettings, cardId, cardType }: AddAnswerDataAtStorySettingsProps) {
		const textDiv = document.createElement('div');
		this.answersMap.forEach((data, answerId) => {
			if (data.cardId !== cardId) {
				return;
			}

			const path = STORY_SETTINGS_COMPONENTS.ANSWER.path({ cardId, answerId });

			if (!get(draftSettings, path)) {
				// add answer to settings
				set(draftSettings, path, {});
			}

			const biggestText: string | undefined = data.text
				.filter(o => o.text)
				.sort((a, b) => parseFloat(b.size) - parseFloat(a.size))[0]?.text;

			let { name } = data;

			if (biggestText) {
				textDiv.innerHTML = biggestText;
				name = textDiv.innerText;
			}

			// store answer name (used at backend to create readable CSV)
			set(draftSettings, `${path}.name`, name);

			if (cardType === CARD_TYPE.SORTABLE_TRIVIA) {
				set(draftSettings, `${path}.index`, data.index);
			}
		});
	}
}

/**
 * List of changes as a result of processing:
 * - get rid of dead(unused) symbol instances
 * - story settings should contain info about existed cards, answers, inputs
 * - fixed/removed invalid data found by `StoryDoctor`
 */
export function processStory(story: StoryFacade, options: { parseSystemFontsUsage?: boolean } = {}) {
	const usedInstanceIdSet = new Set<string>();
	const cardsIdSet = new Set<string>();
	// const answersIdSet = new Set<string>();
	const inputsIdSet = new Set<string>();
	const characterIdSet = new Set<string>();
	const usedSystemFontSet = new Map<string, Exclude<StorySettingsType['systemFonts'], undefined>[number]>();
	const doctor = new StoryDoctor();
	const cardImgMap = new Map<string, Array<{ type: BBMetaType; id: string }>>();
	const answersDataCollector = new AnswerDataCollector();

	/**
	 * Collect id of linked symbol instances in each card
	 */
	function collectInstanceId(component: BBModel | BBSymbolLink) {
		if (component.symbol?.instanceId) {
			usedInstanceIdSet.add(component.symbol.instanceId);
		}
	}

	type AddComponentToStorySettingsProps = {
		component: BBModel;
		cardId: string;
		cardType: CardData['type'];
		index: number;
		path: string;
		draftSettings: Draft<StorySettingsType>;
	};

	function addComponentToStorySettings(props: AddComponentToStorySettingsProps) {
		// collect input data
		if (isFieldBlock(props.component)) {
			inputsIdSet.add(props.component._id);

			const path = `cards.${props.cardId}.input.${props.component._id}`;
			const isExists = get(props.draftSettings, path);

			if (!isExists) {
				// add input to settings
				set(props.draftSettings, path, {});
			}

			// store answer name (used at backend to create readable CSV)
			set(props.draftSettings, `${path}.name`, props.component.uiConfig.editorProps.name);
		}
	}

	const systemFonts = Object.values(SYSTEM_FONTS);
	/**
	 * Find system fonts usage to define which one to load then
	 */
	function parseSystemFontsUsage(component: BBModel) {
		Object.values(component.uiConfig.componentProps?.styles ?? {}).forEach(stateValue => {
			(Object.values(stateValue) as BBStylesProp[]).forEach(platformValue => {
				const ff = platformValue.fontFamily;
				const systemFont = ff ? systemFonts.find(o => o.fontFamily === ff) : undefined;
				if (systemFont) {
					usedSystemFontSet.set(systemFont.url, { url: systemFont.url, fontType: systemFont.fontType });
				}
			});
		});
	}

	function collectComponentsMeta(component: BBModel, cardId?: string) {
		const { metaType } = component.uiConfig.componentProps;
		const isImageType = component.type === COMPONENT_TYPE.IMG || component.type === COMPONENT_TYPE.CARD;
		const isImageMeta = [BBMetaType.bgImage, BBMetaType.image, BBMetaType.answerImage].includes(metaType!);

		// collect card images data
		if (cardId && isImageType && isImageMeta) {
			const styles = component.uiConfig.componentProps?.styles as WithStateAndPlatform<BBStylesProp> | undefined;
			const src = cutURLMethod(styles?.defaultState.desktop.backgroundImage ?? '');
			if (src && getSourceType(src) === 'image') {
				const prev = cardImgMap.get(cardId) ?? [];
				cardImgMap.set(cardId, [...prev, { type: metaType!, id: component._id }]);
			}
		}
	}

	return produce(story.getVersion(StoryFacade.VERSIONS.latest), draft => {
		// forEach story block
		componentWalk(draft.data.elements, function walk({ component }) {
			collectInstanceId(component);
			collectComponentsMeta(component);
			// todo: optimize by checking only components that can have a font family.
			if (options.parseSystemFontsUsage) parseSystemFontsUsage(component);
			componentWalk(component.children, walk);
		});

		// forEach card and block in card
		draft.data.steps.forEach((step, si) => {
			step.cards.forEach((card, ci) => {
				const isCardValid = doctor.checkCard({ card, path: `data.steps.${si}.cards.${ci}` });

				if (!isCardValid) {
					return;
				}

				cardsIdSet.add(card._id);

				if (!draft.settings.cards) {
					draft.settings.cards = {};
				}

				// add card to settings
				if (!draft.settings.cards[card._id]) {
					draft.settings.cards[card._id] = {};
				}

				// store card name (used at backend to create readable CSV)
				set(draft.settings.cards[card._id], 'name', card.name);

				// store card ype (used at backend to generate card template using AI)
				set(draft.settings.cards[card._id], 'type', card.type);

				// store navigation cards data
				if (card.type === CARD_TYPE.NAVIGATION) {
					const facade = new NavigationFacade(card);

					// store navigation card characters (used at backend to create readable CSV)
					if (facade.getCountType(draft.settings) === CountType.character) {
						const cardCharacters = facade.characters;
						draft.settings.characters = {
							...draft.settings.characters,
							...cardCharacters,
						};

						Object.keys(cardCharacters).forEach(charId => characterIdSet.add(charId));
					}
				}

				componentWalkFull({
					elements: card.elements,
					symbols: draft.data.symbols!,
					callback: ({ component, path, stopCurrent, index }) => {
						const isComponentValid = doctor.checkComponent({
							component,
							path: `data.steps.${si}.cards.${ci}.elements.${path}`,
						});

						if (!isComponentValid) {
							stopCurrent();
							return;
						}

						collectInstanceId(component);
						collectComponentsMeta(component, card._id);

						if (options.parseSystemFontsUsage) parseSystemFontsUsage(component);

						answersDataCollector.gatherAnswerData({ component, path, index, cardId: card._id });

						addComponentToStorySettings({
							component,
							path,
							index,
							cardId: card._id,
							cardType: card.type,
							draftSettings: draft.settings,
						});

						// fixme: [TEMP] This code creates a reference to the collection based on the component name.
						// -------------------------------------------- START (temporary solution)
						const { name } = component.uiConfig.editorProps;
						if (name.startsWith('::')) {
							const [, collectionId, dataId, fieldId] = name.split('::');

							// Create text reference
							if (component.type === 'TEXT') {
								if (!Array.isArray(component.children)) {
									const ch = component.children;
									ch.defaultState.desktop = cms.createReference(collectionId, dataId, fieldId);
								}
							}

							// Create color reference
							if (fieldId === 'color') {
								type Styles = WithStateAndPlatform<BBStylesProp>;
								const st = component.uiConfig.componentProps.styles as Styles;
								st.defaultState.desktop.color = cms.createReference(collectionId, dataId, fieldId);
							}

							// Create background-image reference
							if (fieldId === 'backgroundImage') {
								type Styles = WithStateAndPlatform<BBStylesProp>;
								const st = component.uiConfig.componentProps.styles as Styles;
								const reference = cms.createReference(collectionId, dataId, fieldId);
								console.info('=== bg', { reference });
								st.defaultState.desktop.backgroundImage = `${reference}`;
							}
							// todo: connect field to collection, choose collection -> field -> data

							// Mark component as repeatable (should be replaced then with a CMS Repeatable component)
							if (name === '::repeatable::') {
								const cp = component.uiConfig.componentProps;
								cp.collectionId = 'repeat';
							}
							// todo: connect component to collection and make it repeatable
						}
						// -------------------------------------------- END (temporary solution)
					},
					debugIterationCallback: ({ component }) => {
						try {
							if (
								!component ||
								('children' in component &&
									Array.isArray(component?.children) &&
									component.children.some(child => child === null))
							) {
								captureMessage('Invalid children', {
									level: 'error',
									contexts: { data: { component: JSON.stringify(component) } },
								});
							}
						} catch (e) {
							console.error(e);
						}
					},
				});

				// collect card images
				const images = cardImgMap.get(card._id);
				if (images) draft.settings.cards[card._id].images = images;

				// we've looped through all elements in card and ready to store answers data
				answersDataCollector.addAnswerDataAtStorySettings({
					cardId: card._id,
					cardType: card.type,
					draftSettings: draft.settings,
				});
			});
		});

		// modify story to treat an errors
		doctor.heal(draft);

		if (options.parseSystemFontsUsage) {
			draft.settings.systemFonts = Array.from(usedSystemFontSet.values());
		}

		// modify uploaded custom font url with storycardsDomain
		const { fonts } = draft.settings;
		if (fonts) {
			Object.entries(fonts).forEach(([key, value]) => {
				if (value.fontType === 'custom') {
					const updatedUrl = replaceUrlHostname(value.url, story.base.storycardsDomain);
					if (updatedUrl) fonts[key].url = updatedUrl;
				}
			});
		}

		// store card order (used at backend to create readable CSV)
		draft.settings.cardOrder = [...cardsIdSet.values()];

		// forEach symbol
		if (draft.data.symbols) {
			const symbols = Object.values(draft.data.symbols);

			symbols.forEach(draftSymbol => {
				// for each master
				// draftSymbol.master...

				// for each instance
				Object.values(draftSymbol.instance).forEach(draftInstance => {
					if (!usedInstanceIdSet.has(draftInstance._id)) {
						/**
						 * Delete dead(unused) instance
						 *
						 * Story should not contain any symbol instance that is not used.
						 * It may be caused only by mistake,that has not been found.
						 * To keep story clean, it is preferable to track symbols usage (see "collectInstanceId").
						 */
						delete draftSymbol.instance[draftInstance._id];
					}
				});
			});
		}

		// clear story settings (remove data about components, events, cards that does not already exist)
		Object.keys(draft.settings.cards ?? []).forEach(cardId => {
			// card has been deleted
			if (!cardsIdSet.has(cardId)) {
				unset(draft.settings.cards, cardId);
				return;
			}

			const card = draft.settings.cards![cardId];

			// answer has been deleted or answer has characterPoints of character that already does not exist
			Object.entries(card?.answers || []).forEach(([answerId, answerSettings]) => {
				if (answersDataCollector.answersMap.get(answerId)?.cardId !== cardId) {
					unset(draft.settings.cards, [cardId, 'answers', answerId]);
				} else if ('characterPoints' in answerSettings) {
					// check if any answer uses 'characterId' and clear answer if it does
					Object.keys(answerSettings.characterPoints ?? []).forEach(charId => {
						if (!characterIdSet.has(charId)) unset(answerSettings.characterPoints, [charId]);
					});
				}
			});

			// clear from deleted inputs
			const inputs = Object.keys(card?.input || []);
			inputs.forEach(inputId => {
				if (!inputsIdSet.has(inputId)) {
					unset(draft.settings.cards, [cardId, 'input', inputId]);
				}
			});
		});

		// clear story settings (remove data about characters that does not already exist)
		Object.keys(draft.settings.characters ?? {}).forEach(charId => {
			if (!characterIdSet.has(charId)) {
				unset(draft.settings.characters, [charId]);
			}
		});

		// clear
		usedInstanceIdSet.clear();
		cardsIdSet.clear();
		answersDataCollector.answersMap.clear();
		inputsIdSet.clear();
		characterIdSet.clear();
		usedSystemFontSet.clear();
	});
}
