import _, { pick } from 'lodash';
import React, { useMemo } from 'react';
import { Field, FormName } from 'redux-form';
import cn from 'classnames';
import type { BBInheritanceValue, BBStates, StoryMediaPlatform } from 'types/story';
import cms from 'common/utils/cms';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { useAdminSelector } from 'admin/reducers';
import { DATA_SELECTOR } from 'admin/constants/common';
import type { Gap, GridColumn } from 'admin/components/common/Grid/Grid';
import { Grid, Column } from 'admin/components/common/Grid';
import { Checkbox } from 'admin/components/common/Form/Checkbox';
import { selectCurrentPlatform, selectCurrentState } from 'admin/reducers/card-editor/selectors';
import type {
	FieldComponentType,
	FieldConfigSet,
	FieldSettings,
	FieldSettingsSet,
	InspectorTypes,
} from 'admin/components/pages/Story/CardEditor/Inspector/types';
import { TextField, PFTextProps } from './TextField';
import { NumberField, PFNumberProps } from './NumberField';
import { MediaField } from './MediaField';
import mfCSS from './MediaField/MediaField.scss';
import { ColorField } from './ColorField';
import { Label } from './Label';
import { PFToggleProps, Toggle } from './Toggle';
import Select, { SelectProps } from './Select';
import IconSelect, { IconSelectProps } from './IconSelect';
import { Radio } from './Radio';
import { PFSliderProps, Slider } from './Slider';
import TextareaAutosize from './TextareaAutosize';
import { DatePicker, PFDatePickerProps } from './Date';
import { GRID_COLUMN_2, PROP_FIELD_TYPES } from './constants';
import css from './PropField.scss';

const FIELDS_MAP = {
	[PROP_FIELD_TYPES.text]: TextField,
	[PROP_FIELD_TYPES.number]: NumberField,
	[PROP_FIELD_TYPES.color]: ColorField,
	[PROP_FIELD_TYPES.select]: Select,
	[PROP_FIELD_TYPES.iconSelect]: IconSelect,
	[PROP_FIELD_TYPES.checkbox]: Checkbox, // TODO design custom PropField component
	[PROP_FIELD_TYPES.toggle]: Toggle,
	[PROP_FIELD_TYPES.radio]: Radio,
	[PROP_FIELD_TYPES.textareaAutosize]: TextareaAutosize,
	[PROP_FIELD_TYPES.media]: MediaField,
	[PROP_FIELD_TYPES.slider]: Slider,
	[PROP_FIELD_TYPES.date]: DatePicker,
};

export const getInheritanceInfo = ({
	currentMediaQuery,
	currentState,
	inheritance,
	propertyName,
}: {
	currentMediaQuery: StoryMediaPlatform;
	currentState?: BBStates;
	inheritance?: BBInheritanceValue;
	propertyName: string;
}) => {
	const imq = _.get(inheritance, 'mq');
	const ist = _.get(inheritance, 'state');

	const isUniformProp = _.has(UNIFORM_PROPS, propertyName);
	const isDefault = !imq && !ist && !isUniformProp;
	const isInherited = (imq && imq !== currentMediaQuery) || (ist && ist !== currentState);
	const isAssigned = (!isDefault && !isInherited) || isUniformProp;
	const type = isAssigned ? 'assigned' : isInherited ? 'inherited' : 'default';

	return { isDefault, isInherited, isAssigned, isUniformProp, type };
};

export type PropFieldType = {
	inheritance?: BBInheritanceValue;
	className?: string;
	grid?: {
		disabled?: boolean;
		columnsCount?: GridColumn;
		columns?: any[];
		gap?: Gap;
	};
	labelProps?: {
		component?: string;
		children?: React.ReactNode[] | React.ReactNode;
	};
	fieldProps: {
		component: FieldComponentType;
		name: string;
		placeholder?: string;
		autoSelect?: boolean;
		clearable?: boolean;
		eventListeners?: ReturnType<InspectorTypes.GetFieldListeners>;
		forcedValue?: string;
		showErrorsOn?: string;
		customData?: {};
		disabled?: boolean;
		decimals?: number;
		options?: SelectProps['options'] | IconSelectProps['options'];
		className?: string;
		max?: number;
		icon?: string;
		min?: number;
		checked?: boolean;
		unit?: boolean;
		showLink?: boolean;
		fieldRef?: React.MutableRefObject<HTMLInputElement | null>;
		globalError?: boolean;
		fieldOnly?: boolean;
		defaultValue?: any;
		prop?: string;
		minRows?: number;
	} & Pick<SelectProps, 'searchBy' | 'showSearch' | 'hideUnlistedValue' | 'afterOptions'> &
		Pick<PFTextProps, 'saveOn'> &
		Pick<PFToggleProps, 'toggleView'> &
		Pick<PFNumberProps, 'numberView' | 'dragStep'> &
		Pick<PFNumberProps, 'boundWithFields'> &
		Pick<IconSelectProps, 'activeIconStyle'> &
		Pick<PFSliderProps, 'range' | 'marks' | 'tooltip' | 'step'> &
		Pick<PFDatePickerProps, 'minDate' | 'maxDate' | 'mode'>;
};

const defaultProps = {
	className: '',
	labelProps: {},
	grid: GRID_COLUMN_2({
		col1: { colSpan: '6' },
		col2: { colSpan: '6' },
	}),
};

const PropField = ({
	fieldProps,
	inheritance,
	grid = defaultProps.grid,
	className = defaultProps.className,
	labelProps = defaultProps.labelProps,
}: PropFieldType) => {
	const { name } = fieldProps;
	const currentMediaQuery = useAdminSelector(selectCurrentPlatform);
	const currentState = useAdminSelector(selectCurrentState);
	const propertyName = useMemo(() => {
		return _.last(name.split('.')) ?? '';
	}, [name]);

	const inheritanceInfo = getInheritanceInfo({
		inheritance,
		currentState,
		currentMediaQuery,
		propertyName,
	});

	const classNames = cn(css.pf, css[fieldProps.component], className, {
		[css.disabled]: fieldProps.disabled,
		[mfCSS.pfMediaRoot]: fieldProps.component === PROP_FIELD_TYPES.media,
	});

	const label = <FormName>{v => <Label htmlFor={`${v.form}.${fieldProps.name}`} {...labelProps} />}</FormName>;
	const field = <Field {...fieldProps} component={FIELDS_MAP[fieldProps.component]} />;
	const reset = <input type="hidden" name={fieldProps.name} onClick={fieldProps.eventListeners?.onReset} />;
	const dataSelector = DATA_SELECTOR.PROP_FIELD(propertyName);
	const cmsMark = cms.isCollectionReference(inheritance?.value ?? '') ? <span className={css.pfCmsMark} /> : null;

	if (grid?.disabled) {
		return (
			<div className={classNames} data-inheritance={inheritanceInfo.type} data-selector={dataSelector}>
				{label}
				{field}
				{reset}
				{cmsMark}
			</div>
		);
	}

	if (!grid) {
		return null;
	}

	const columns = grid.columns || [];

	return (
		<Grid
			className={classNames}
			data-inheritance={inheritanceInfo.type}
			columns={grid.columnsCount ?? defaultProps.grid.columnsCount}
			columnGap={grid.gap}
			data-selector={dataSelector}
		>
			<Column className={css.pfCol} {...columns[0]}>
				{label}
				{cmsMark}
			</Column>
			<Column {...columns[1]} className={cn(css.pfCol, css.pfColField, columns[1].className)}>
				{field}
				{reset}
			</Column>
		</Grid>
	);
};

PropField.fieldType = PROP_FIELD_TYPES;

/**
 * Get 'fieldProps.name' prop for PropField component to represent path to value at 'componentProps' from card elements
 *
 * @param name is an editable property name
 * @param prop is a key at 'componentProps' where name is holden
 *
 * todo: consider of idea to get rid of 'getPFCompPropName' and provide to 'FieldSettingsSet.fieldProps.name' just a
 *       string, then maybe it's possible to do all this job with this string inside of 'getPropFieldPropsSet'
 *       to generate final name value
 */

type GetPFCompPropName = (name: string, prop?: 'styles' | 'other' | 'uniform') => FieldSettings['fieldProps']['name'];
export const getPFCompPropName: GetPFCompPropName =
	(name, prop = 'styles') =>
	p => {
		const basePath = `elements.${p.path}.uiConfig.componentProps`;
		const propPath = prop === 'uniform' ? '' : `.${prop}.${p.currentState}.${p.currentMediaQuery}`;
		return `${basePath}${propPath}.${name}`;
	};

export const getPFChildrenPath = (params: {
	path: string;
	currentState: BBStates;
	currentMediaQuery: StoryMediaPlatform;
}) => `elements.${params.path}.children.${params.currentState}.${params.currentMediaQuery}`;

type PFPSetProps = Parameters<FieldSettings['fieldProps']['name']>[0] & {
	fieldsConfig: FieldSettingsSet;
	fields: FieldConfigSet;
	getFieldListeners: InspectorTypes.InspectorToolComponentProps['getFieldListeners'];
	updateTarget?: Parameters<InspectorTypes.InspectorToolComponentProps['getFieldListeners']>[0]['updateTarget'];
};
/**
 * Get list of 'fieldProps' properties by fields config for a PropField component
 *
 * Requirements for optional params:
 * @param props.path                 is required in case of property stored in 'BBModel'
 * @param props.currentState         is required if property value is based on 'state'
 * @param props.currentMediaQuery    is required if property value is based on 'platform'
 * @param props.cardId               is required if path to property is lies though the `card._id`
 * @param props.elementId            is required if path to property is lies though the `element._id`
 * @param props.fields               is required if property can be inherited/assigned per platform/state/etc.
 */
export const getPropFieldPropsSet = <FieldName extends string>(props: PFPSetProps) =>
	Object.keys(props.fieldsConfig).reduce<Record<FieldName, PropFieldType>>(
		(acc, field) => {
			const { fieldProps, eventListeners, className, grid, labelProps } = props.fieldsConfig[field];
			const commonListeners = props.getFieldListeners(
				{
					component: fieldProps.component,
					updateTarget: props.updateTarget,
				},
				field
			);

			acc[field] = {
				grid,
				className,
				labelProps: labelProps?.(props.fields[field], props.fields),
				fieldProps: {
					...fieldProps,
					name: fieldProps.name({
						path: props.path,
						currentState: props.currentState,
						currentMediaQuery: props.currentMediaQuery,
						cardId: props.cardId,
						elementId: props.elementId,
					}),
					eventListeners:
						Array.isArray(eventListeners) && eventListeners.length
							? pick(commonListeners, eventListeners)
							: commonListeners,
				},
				inheritance: props.fields[field]?.inheritance,
			};
			return acc;
		},
		{} as Record<FieldName, PropFieldType>
	);

export default PropField;
