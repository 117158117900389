import type { CSSProperties, MutableRefObject, ReactNode, FocusEventHandler, MouseEventHandler } from 'react';
import type { SCFontItem } from 'src/types/fonts';
import type { IProfilePictureFile } from 'src/types/user';
import type { IOrganizationFonts, IOrganizationIntegrations } from 'src/types/organization';
import type { CustomVariable } from 'utils/variables/types';
import type { CountType } from 'utils/facades/navigation-card-facade';
import { CSS_PROPS, OTHER_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';
import {
	CARD_TYPE,
	COMPONENT_STATES,
	COMPONENT_TYPE,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	ELEMENT_EDIT_MODE,
	LAYER_TYPE,
	CONNECTION_TYPES,
	STORY_TYPE,
	CONTENT_ALIGN_Y,
	TRANSITION_TYPE,
	Pin,
} from 'common/constants';
import type { TransitionHandlerContextType } from 'client/components/common/CardTransition/TransitionHandler';
import {
	FieldBBErrorType,
	FieldBBType,
	SelectBBOptionsOrder,
} from 'client/components/common/BuildingBlocks/Fields/constants';
import type { ITraverseTreeOptionsListeners } from 'client/components/common/BuildingBlocks/utils/traverse-tree-types';
import type { ShareType, ShareTarget, ShareOgSource } from 'client/components/common/BuildingBlocks/Share/types';
import {
	ANIMATE_DIRECTION,
	SHAPE_LINE_DIRECTION,
	SHAPE_TYPE,
} from 'client/components/common/BuildingBlocks/Shape/Shape';
import type { BoxType } from 'client/components/common/BuildingBlocks/Box/constants';
import { SELECTION_STAGE } from 'client/components/common/SelectionHint/utils';
import { BUTTON_SHAPE_TYPES } from 'client/components/common/BuildingBlocks/Button';
import type { TimerType } from 'client/components/common/BuildingBlocks/Timer/Timer';
import type { AnimationData } from 'client/components/common/BuildingBlocks/animation';
import type { FormCardContextType } from 'client/components/common/StoryCard/Form/types';
import type { DateDelimiter, DateFormat } from 'client/components/common/BuildingBlocks/Fields/types';
import type { LottieDirection, LottieTrigger } from 'client/components/common/BuildingBlocks/Lottie/utils';
import type { AnswerProgressType } from 'client/components/common/BuildingBlocks/AnswerProgress/AnswerProgress';
import { SortableMode, SortableType } from 'client/components/common/BuildingBlocks/SortableBox/constants';
import {
	OverlayAnimationPreset,
	OverlayType,
	OverlayShowOn,
} from 'client/components/common/BuildingBlocks/Overlay/constants';
import {
	CounterAnimation,
	CounterDivider,
	CounterType,
} from 'client/components/common/BuildingBlocks/Counter/constants';
import {
	SliderArrowDir,
	SliderDir,
	SliderEffect,
	PaginationType,
} from 'client/components/common/BuildingBlocks/Slider/constants';

export enum EditorMode {
	DESIGN = 'DESIGN',
	CONTENT = 'CONTENT',
}

export enum ProgressDirection {
	ltr = 'left',
	rtl = 'right',
}

export enum BBMetaType {
	title = 'title',
	subtitle = 'subtitle',
	questionText = 'questionText',
	answerText = 'answerText',
	answerImage = 'answerImage',
	image = 'image',
	bgImage = 'bgImage',
}

export type ComponentTypes = ValuesType<typeof COMPONENT_TYPE>;

export type BBStates = ValuesType<typeof COMPONENT_STATES>;

export type StoryMediaPlatform = ValuesType<typeof DEFAULT_MEDIA_QUERY_PLATFORMS>;

export type LayerType = ValuesType<typeof LAYER_TYPE>;

export type WithStateAndPlatform<T, State extends BBStates = BBStates> = Record<State, Record<StoryMediaPlatform, T>>;

type CounterStyle = Partial<Record<ValuesType<typeof CSS_PROPS.counter.unit.background>, string>> &
	Partial<Record<ValuesType<typeof CSS_PROPS.counter.unit.borders>, string>> &
	Partial<Record<ValuesType<typeof CSS_PROPS.counter.unit.padding>, string>> &
	Partial<Record<ValuesType<typeof CSS_PROPS.counter.labelStyle.text>, string>> &
	Partial<Record<ValuesType<typeof CSS_PROPS.counter.labelStyle.margin>, string>> &
	Partial<Record<typeof CSS_PROPS.counter.gap, string>>;

export type BBStylesProp = CSSProperties &
	CounterStyle & {
		borderStyle?: 'none' | 'solid' | 'none none solid none';
		backgroundSize?: string;
		backgroundPosition?: string;
		[CSS_PROPS.text.lineClamp]?: string;
		[CSS_PROPS.text.lineClampColor]?: string;
		[CSS_PROPS.custom.trackColor]?: string;
		[CSS_PROPS.custom.progressColor]?: string;
		[CSS_PROPS.custom.progressDir]?: ProgressDirection;
	};

export type BBOtherProp = {
	[OTHER_PROPS.box.boxType]?: BoxType;
	shapeType?: ValuesType<typeof SHAPE_TYPE>;
	lineDir?: ValuesType<typeof SHAPE_LINE_DIRECTION>;
	lineSize?: string;
	animateDir?: ValuesType<typeof ANIMATE_DIRECTION>;
	backgroundVideo?: string;
	backgroundPoster?: string;
	videoSrc?: string;
	videoPoster?: string;
	muted?: boolean;
	loop?: boolean;
	controls?: boolean;
	autoplay?: boolean;
	[OTHER_PROPS.pin.pinType]?: Pin;
	[OTHER_PROPS.card.tint]?: string;
	embedCode?: string;
	contentAlignY?: ValuesType<typeof CONTENT_ALIGN_Y>;
	contentHeight?: string;
	contentMinHeight?: string;
	contentMarginTop?: string;
	contentMarginBottom?: string;
	contentMarginLeft?: string;
	contentMarginRight?: string;
	buttonShapeType?: ValuesType<typeof BUTTON_SHAPE_TYPES>;
	[OTHER_PROPS.lottie.ltSrc]?: string;
	[OTHER_PROPS.lottie.ltLoop]?: boolean;
	[OTHER_PROPS.lottie.ltSpeed]?: string;
	[OTHER_PROPS.lottie.ltDir]?: LottieDirection;
	[OTHER_PROPS.lottie.ltKeepRatio]?: boolean;
	[OTHER_PROPS.lottie.ltTrigger]?: LottieTrigger;
	[OTHER_PROPS.lottie.ltContainer]?: string;
	[OTHER_PROPS.lottie.ltOffset]?: [number, number];
	[OTHER_PROPS.overlay.overlayBackdrop]?: string;
	[OTHER_PROPS.overlay.overlayBackdropVisible]?: boolean;
	[OTHER_PROPS.overlay.overlayVisible]?: boolean;
	[OTHER_PROPS.overlay.overlayAnimationPreset]?: {
		enter: OverlayAnimationPreset;
		exit: OverlayAnimationPreset;
	};
	[OTHER_PROPS.sortable.dragContainerOffset]?: string;
	[OTHER_PROPS.counter.counterUnits]?: string;
	[OTHER_PROPS.counter.counterDivider]?: CounterDivider;
	[OTHER_PROPS.counter.counterAnimation]?: CounterAnimation;
	[OTHER_PROPS.counter.counterLabels]?: boolean;
	[OTHER_PROPS.slider.speed]?: string;
	[OTHER_PROPS.slider.autoplayDelay]?: string;
	[OTHER_PROPS.slider.spaceBetween]?: string;
	[OTHER_PROPS.slider.slidesPerView]?: string;
	[OTHER_PROPS.slider.sliderDirection]?: SliderDir;
	[OTHER_PROPS.slider.sliderMousewheel]?: boolean;
	[OTHER_PROPS.slider.centeredSlides]?: boolean;
	[OTHER_PROPS.slider.sliderFreeMode]?: boolean;
	[OTHER_PROPS.slider.slideEffect]?: SliderEffect;
	[OTHER_PROPS.sliderPagination.paginationType]?: PaginationType;
};

export type BBAnimationProp = WithStateAndPlatform<Record<string, AnimationData>, 'defaultState'>;

export type SelectBBOption = {
	label: string;
	value?: string; // Defaults to "label" if not provided
};

export type OverlayTriggersProp = {
	show: string[];
	hide: string[];
};

/**
 * @info: client building block property
 */
export type BBUiConfig = {
	layer?: LayerType;
	editorProps: {
		name: string;
		selectable: boolean;
		draggable: boolean;
		expandable: boolean;
		expanded?: boolean;
		[UNIFORM_PROPS.fieldErrorShow]?: string;
	};
	componentProps: {
		// TODO: styles and other are optional for symbol instances!
		// TODO: styles & other change to WithStateAndPlatform<DataType>
		styles: BBStylesProp;
		other: BBOtherProp;
		animation?: BBAnimationProp;

		// custom props
		[UNIFORM_PROPS.btnLink]?: string;
		[UNIFORM_PROPS.btnNavLink]?: string;
		[UNIFORM_PROPS.btnBlockLink]?: string;
		[UNIFORM_PROPS.blockLinkTarget]?: boolean;
		[UNIFORM_PROPS.btnTarget]?: boolean;
		[UNIFORM_PROPS.btnSubmit]?: boolean;
		[UNIFORM_PROPS.shareType]?: ShareType;
		[UNIFORM_PROPS.shareTarget]?: ShareTarget;
		[UNIFORM_PROPS.shareCustomUrl]?: string;
		[UNIFORM_PROPS.shareOgSource]?: ShareOgSource;
		[UNIFORM_PROPS.fieldType]?: FieldBBType;
		[UNIFORM_PROPS.fieldError]?: Record<FieldBBErrorType, /* error message */ string>;
		[UNIFORM_PROPS.fieldRequired]?: boolean;
		[UNIFORM_PROPS.fieldFullName]?: boolean;
		[UNIFORM_PROPS.telCountry]?: string;
		[UNIFORM_PROPS.selectIsMulti]?: boolean;
		[UNIFORM_PROPS.selectOptions]?: SelectBBOption[];
		[UNIFORM_PROPS.selectOptionsOrder]?: SelectBBOptionsOrder;
		[UNIFORM_PROPS.title]?: string;
		[UNIFORM_PROPS.alt]?: string;
		// value in seconds
		[UNIFORM_PROPS.answerTimeout]?: number | string;
		// Bind any of swipe actions to some component by id, to be able to call his handler on demand
		[UNIFORM_PROPS.onSwipe]?: {
			// Component id.
			left?: string;
			// Component id
			right?: string;
		};
		// Timer BB
		[UNIFORM_PROPS.timerTime]?: number;
		[UNIFORM_PROPS.timerType]?: TimerType;
		[UNIFORM_PROPS.tag]?: string;
		[UNIFORM_PROPS.fieldPlaceholder]?: string;
		[UNIFORM_PROPS.answerProgressType]?: AnswerProgressType;
		[UNIFORM_PROPS.dpFormat]?: DateFormat;
		[UNIFORM_PROPS.dpDelimiter]?: DateDelimiter;
		[UNIFORM_PROPS.metaType]?: BBMetaType;
		[UNIFORM_PROPS.overlayType]?: OverlayType;
		[UNIFORM_PROPS.overlayShowOn]?: OverlayShowOn;
		[UNIFORM_PROPS.overlayTriggers]?: OverlayTriggersProp;
		[UNIFORM_PROPS.sortableStartItems]?: string;
		[UNIFORM_PROPS.sortableNavTimeout]?: string;
		[UNIFORM_PROPS.sortableOrder]?: { [answerId: string]: { sortOrder: number } };
		[UNIFORM_PROPS.sortableMode]?: SortableMode;
		[UNIFORM_PROPS.counterType]?: CounterType;
		[UNIFORM_PROPS.counterDate]?: string;
		[UNIFORM_PROPS.sliderArrowDir]?: SliderArrowDir;
		[UNIFORM_PROPS.collectionId]?: string;
	};
	[UNIFORM_PROPS.expose]?: {
		// expose (transition) effect of the Card, Content or Float blocks
		[UNIFORM_PROPS.effect]: ValuesType<typeof TRANSITION_TYPE>;
	};
	nodeProps: {
		id: string;
		className?: string;
		style?: CSSProperties;
		// provided to rendered blocks at client side
		'data-bb'?: ComponentTypes;
		'data-stretch'?: 'x' | 'y';
	};
};

type BBComponentProps = BBUiConfig['componentProps'];

export type BBComponentPropsInheritance = Partial<
	Record<keyof Omit<BBComponentProps, 'styles' | 'other'>, BBInheritanceValue[] | undefined>
> &
	Record<
		keyof Pick<BBComponentProps, 'styles'>,
		Partial<Record<keyof BBComponentProps['styles'], BBInheritanceValue[] | undefined>>
	> &
	Record<
		keyof Pick<BBComponentProps, 'other'>,
		Partial<Record<keyof BBComponentProps['other'], BBInheritanceValue[] | undefined>>
	>;

export type BBInheritanceValue = {
	secondValue?: any;
	value?: any;
	state?: BBStates;
	mq?: StoryMediaPlatform;
};

export type BBInheritanceParent = {
	_id: string;
	path: string;
	type: ComponentTypes;
	name: string;
	inheritance: {
		componentProps?: BBComponentPropsInheritance;
	};
};

/**
 * @info: props passed by traverseTree to client components (building blocks), in case target is editor
 */
export type BBEditableModeProps = {
	nodeProps?: {
		'data-path': string;
		'data-pin': string;
		'data-lock': boolean;
		className?: string;
		style?: CSSProperties;
	};
	eventListeners?: Partial<ITraverseTreeOptionsListeners>;
	inheritance?: {
		componentProps: BBComponentPropsInheritance;
		parent: BBInheritanceParent[];
	};
};

export type BBSymbolLink = { symbol: BBSymbolInfo };

export type BBModelTextChild = WithStateAndPlatform<string, 'defaultState'>;

export type BBModel = {
	_id: string;
	type: ComponentTypes;
	uiConfig: BBUiConfig;
	children: BBModel[] | BBModelTextChild;
} & Partial<BBSymbolLink>;

export type BBSymbolInstance = BBModel & BBSymbolLink;

export type CardFlowEventType = ValuesType<typeof CONNECTION_TYPES>;

/**
 * @info: src/routes/admin/components/pages/Story/Flow/Connector/ConnectorSvg/utils.js -> CardEvent
 */
export type CardFlowEvent = {
	_id: string;
	name: string;
	score?: number[];
	componentId?: string;
	connection?: {
		type: CardFlowEventType;
		pointId?: string;
	};
};

export type CardData = {
	_id: string;
	type: ValuesType<typeof CARD_TYPE>;
	name: string;
	settings: {};
	elements: [
		BBModel & { type: typeof COMPONENT_TYPE.FLOAT_ABOVE; children: BBModel[] },
		BBModel & { type: typeof COMPONENT_TYPE.CONTENT; children: BBModel[] },
		BBModel & { type: typeof COMPONENT_TYPE.FLOAT_BELOW; children: BBModel[] },
		BBModel & { type: typeof COMPONENT_TYPE.CARD; children: BBModel[] },
	];
	events: CardFlowEvent[];
};

export type CardDataWithStepInfo = CardData & { step: { _id: StoryStep['_id'] } };

export type BBSymbolInfo = {
	masterId: string; // "masterId" links to the "_id" of symbol MASTER. Also equal to 1st symbol "_id".
	instanceId: string; // "instanceId" links to the "_id" of symbol INSTANCE
	childId?: string; // "childId" links to the "_id" of related child in symbol MASTER
};

export type SelectedBBSingle = {
	_id: string;
	type: ComponentTypes;
	path: string;
	uiConfig: BBUiConfig;
	selectionStage: ValuesType<typeof SELECTION_STAGE>;
	inheritance: {
		componentProps: BBComponentPropsInheritance;
		parent: BBInheritanceParent[];
	};
} & Partial<BBSymbolLink>;

export type SelectedBB = Record<string, SelectedBBSingle>;

export type EditableStateInfo = {
	state: BBStates;
	source: {
		id: string;
		path: string;
		type: ComponentTypes | '';
		layer: LayerType;
		states?: BBStates[];
	};
};

export type StorySymbol = {
	master: BBModel;
	instance: Record<string, BBSymbolInstance>;
};

export type StorySymbols = Record<string, StorySymbol>;

type BBStatesProp = Record<Exclude<BBStates, typeof COMPONENT_STATES.DEFAULT>, boolean>;

export type BBCommonProps = {
	_id: string;
	uiConfig: BBUiConfig;
	editableModeProps?: BBEditableModeProps;
	children: ReactNode;
	currentMediaQuery: StoryMediaPlatform;
	mediaQuery: StoryMediaQuery;
	states: BBStatesProp; // building block current states (hover, correct, etc.)
	// parent states currently provided only by Answer to his children and by Swipe
	parentStates?: BBStatesProp; // parent building block current states (hover, correct, etc.)
	setStates: (state: Partial<Record<BBStates, boolean>>) => void; // callback to set props.states
	stateAttrs: { [key: string]: string }; // props.states converted as data attributes to assign to DOM node
	eventListeners?:
		| {
				// editor mode
				onDoubleClickCapture: MouseEventHandler;
				onFocus: FocusEventHandler;
				onMouseDown: MouseEventHandler;
				onMouseDownCapture: MouseEventHandler;
				onMouseEnter: MouseEventHandler;
				onMouseLeave: MouseEventHandler;
				onClick?: MouseEventHandler;
		  }
		| {
				// non-editor mode
				onMouseEnter: MouseEventHandler;
				onMouseLeave: MouseEventHandler;
				onClick?: MouseEventHandler;
		  };
	isEditableMode?: boolean;
	contentAlignY?: ValuesType<typeof CONTENT_ALIGN_Y>;
	onEditableMouseEnter?: MouseEventHandler;
	onEditableMouseLeave?: MouseEventHandler;
	onEditableClick?: MouseEventHandler;
	onEditableFocus?: FocusEventHandler;
	containerRef?: MutableRefObject<HTMLElement>;
	formContext?: FormCardContextType;
	type: ComponentTypes;
	isCardTree: boolean;
	editorMode: EditorMode;
	isEmbed: boolean;
	getComputedComponentProps: (state: BBStates) => ReturnType<ComputedComponentPropsFn>;
	/**
	 * @desc used to pass props into some BB for reuse it in other building block (e.g. Img in Share)
	 */
	custom?: {
		style: CSSProperties;
	};
} & Partial<BBSymbolLink> &
	TransitionHandlerContextType;

export type BBEditMode = ValuesType<typeof ELEMENT_EDIT_MODE>;

export type CardEditorSymbolInfo = {
	editMode: BBEditMode;
	masterId?: string;
	instanceId?: string;
};

export type StorySingleMediaQueryConfig = {
	maxWidth: number | null;
	minWidth: number | null;
	uiName: string;
};

export type StoryMediaQuery = {
	defaultPlatform: StoryMediaPlatform;
	config: Record<StoryMediaPlatform, StorySingleMediaQueryConfig>;
};

export type StoryStep = {
	_id: string;
	cards: CardData[];
};

type Screenshot = Record<StoryMediaPlatform, { filepath: string; hosting: string }>;

export type StorySettingsCards = {
	[cardId: string]: StorySettingsOfCard;
};

export type StorySettingsOfCard = {
	// added only in the `published` version
	type?: CardData['type'];
	answers?: {
		[answerId: string]:
			| { name: string; isCorrect?: boolean } // Trivia, TrueOrFalse
			| { name: string; score?: string; index: number } // SortableTrivia
			| { name: string; score?: string } // PersonalityTest with "countType: score"
			// PersonalityTest with "countType: character"
			| { name: string; characterPoints?: { [characterId: string]: string } };
	};
	input?: Record<string, { name?: string }>;
	score?: string;
	showCorrect?: boolean; // whether Answer has correct/incorrect states
	showResults?: boolean; // whether to render Answer with a special blocks for live results
	countType?: CountType; // way the Navigation card should count user result
	sortableType?: SortableType; // the way `SortableBox` should perform sorting in `SortableTrivia` card
	name?: string;
	seo?: { title?: string; description?: string; keywords?: string };
	share?: { title?: string; description?: string; msg?: string; image?: string };
	answersMinMax?: [min: number, max: number];
	forceSubmitBtn?: boolean;
	[UNIFORM_PROPS.expose]?: {
		// expose (transition) duration of the Card, Content or Float blocks
		[UNIFORM_PROPS.duration]: string; // e.g. '3'
	};
	images?: { type: BBMetaType; id: /* related component._id */ string }[];
};

export type StorySettingsType = {
	cardOrder?: string[]; // published version only
	cards?: StorySettingsCards;
	characters?: {
		[characterId: string /* characterId is "event._id" of Navigation card with "countType: character" */]: {
			name: string;
			cardId: string;
		};
	};
	seo?: { title?: string; description?: string; keywords?: string; favicon?: string; webclip?: string };
	share?: { title?: string; description?: string; image?: string };
	fonts?: IOrganizationFonts;
	// list of used system fonts in the published version to load then
	systemFonts?: Array<Pick<SCFontItem, 'url' | 'fontType'>>;
	integrations?: IOrganizationIntegrations;
	restrictions?: {
		oncePerUser?: boolean;
		isDisabledApi?: boolean;
		isHideCredit?: boolean;
		isAutoRedirect?: boolean;
	};
	screenshot?: Record<string, Screenshot>;
	variables?: {
		source?: Array<{ url: string; variables: CustomVariable[] }>;
		custom?: CustomVariable[];
	};
	// story template id. exists if story created from template
	templateId?: number;
	// story generator data. exists if story created using AI generator
	generator?: { id: string; jobId: string };
	gdpr?: {
		enabled?: boolean;
		interactionsDisabled?: boolean;
	};
};

export type StoryVersionType = {
	storyId: string;
	version: string;
	settings: StorySettingsType;
	createdAt: string;
	updatedAt: string;
	changelog: null;
	url?: string; // exists only on published stories
	data: {
		mediaQuery: StoryMediaQuery;
		elements: BBModel[];
		steps: StoryStep[];
		symbols?: StorySymbols;
		appVersion: string;
		// is card editor auto save enabled. `true | undefined` = enabled; `false` = disabled
		cardEditorAutoSync?: boolean;
	};
};

export type StoryVersions = {
	[key: string]: StoryVersionType;
};

interface StorycardsDomain {
	domain: 'dev.storycards.co' | 'storycards.co' | 'stories.sc' | 'story.dev.story-cards.com';
	images: 'images-dev.storycards.co' | 'images.storycards.co' | 'ix.stories.sc' | 'dev-storycards.imgix.net';
	assets:
		| 'assets-dev.storycards.co'
		| 'assets.storycards.co'
		| 'assts.stories.sc'
		| 'dev-low-cost-galleryassetsbucket9eb44ac2-pkzruw7z30pc.s3.eu-west-1.amazonaws.com';
	videos: 'assts.stories.sc' | 'dev-low-cost-galleryassetsbucket9eb44ac2-pkzruw7z30pc.s3.eu-west-1.amazonaws.com';
	provider: 'CloudFront' | 'Cloudflare';
	updatedAt: Date;
	createdAt: Date;
}

export type StoryModel = {
	id: string;
	templateId?: number;
	clientStoryId: string;
	domainId: null | number;
	teamId: null | string;
	status: 'draft' | 'published';
	createdBy: string;
	organizationId: string;
	name: string;
	type: ValuesType<typeof STORY_TYPE> | null; // only old stories have null type
	language: string;
	tags: string[];
	createdAt: string; // ISO
	updatedAt: string; // ISO
	storyVersions: StoryVersions;
	storycardsDomain?: StorycardsDomain;
	storycardsDomainId?: StorycardsDomain['domain'];
	member: {
		id: string;
		name: string;
		profilePictureFile: IProfilePictureFile;
		// get-organization-stories response only
		email?: string;
	};
	team?: {
		id: string;
		name: string;
	};
	opCounter?: number;
};

export type PublishedStoryJSON = Omit<StoryModel, 'storyVersions'> & {
	storyVersion: StoryModel['storyVersions'][string];
};

export type SpreadsheetRange = {
	id: number;
	storySheetIntegrationId: string;
	sheetId: number;
	sheetName: string;
	type: 'single_cell';
	label: string;
	range: string;
	createdAt: string;
	updatedAt: string;
};

export type CardEditorType = {
	data: CardData | null;
	symbols: StorySymbols;
	currentMediaQuery: StoryVersionType['data']['mediaQuery']['defaultPlatform'];
	cardPath: string;
	state: EditableStateInfo;
	storySettings: StorySettingsType;
	storyElements: BBModel[];
	// synchronized by state-sync-middleware.ts at(ISO time)
	stateSyncAt: null | string;
};

export type DictionaryType = { [key: string]: string };

export type BBOptions = { dictionary: DictionaryType };

export type CardTemplate = {
	id: string;
	organizationId: null | string;
	memberId?: string | null;
	name: string;
	subtitle: string;
	description: string;
	metadata: {
		version: string; // app version when template was created
		cardId: string; // original card id
		storyId: string; // original story id
		screenshot?: Screenshot; // screenshot path for a new templates
		clientStoryId: StoryModel['clientStoryId'];
		defaultPlatform: StoryMediaQuery['defaultPlatform']; // mediaQuery.defaultPlatform of the original story
		storySettings: StoryVersionType['settings'];
	};
	type: 'card';
	language: string;
	categories: string[];
	isPublic?: boolean;
	uiTarget?: ValuesType<typeof STORY_TYPE>[] | null;
	hoverImage?: number | null;
	hoverImageFile?: IAssetResource | null;
	coverImage?: number | null;
	coverImageFile?: IAssetResource | null;
	createdAt: string;
	updatedAt: string;
	editor: {
		card: {
			_id: CardData['_id'];
			type: CardData['type'];
			name: CardData['name'];
			elements: CardData['elements'];
			settings: CardData['settings'];
			events: CardData['events'];
		};
		symbols?: StoryVersionType['data']['symbols'];
		storyElements?: StoryVersionType['data']['elements'];
	};
};

export type CardTemplateWithoutData = Omit<CardTemplate, 'editor'>;

export type StoryTemplateWithoutData = {
	id: number;
	storyId: string;
	coverCardId?: string;
	title: string;
	description: string | null;
	organizationId: string | null; // null is only for template from scratch
	timesUsed: number;
	language: StoryModel['language'];
	updatedAt: string;
	createdAt: string;
	story: Pick<
		StoryModel,
		| 'id'
		| 'clientStoryId'
		| 'teamId'
		| 'organizationId'
		| 'name'
		| 'language'
		| 'status'
		| 'type'
		| 'tags'
		| 'updatedAt'
		| 'createdAt'
	> & {
		published: Pick<StoryVersionType, 'url' | 'version' | 'settings' | 'updatedAt' | 'createdAt'> & {
			settings: StoryVersionType['settings'] & {
				metadata: { cardTypes: CardData['type'][]; totalCards: number };
			};
		};
	};
};

export type StoryTemplateWithData = StoryTemplateWithoutData & {
	story: StoryTemplateWithoutData['story'] & {
		published: StoryTemplateWithoutData['story']['published'] & { data: StoryVersionType['data'] };
	};
};

export type ComputedComponentPropsFn = (props: {
	element: BBModel; // note: componentProps with state & platforms (not computed one)
	mediaKeysInOrderOfInheritance: StoryMediaPlatform[];
	state: BBStates;
}) => {
	value: BBModel['uiConfig']['componentProps'];
	inheritance: BBComponentPropsInheritance;
};

export type ComputedValueFn = (props: {
	source: string | number | boolean | object | undefined;
	sourceKey: keyof BBModel['uiConfig']['componentProps'] | 'textChildren'; // componentProps
	mediaKeysInOrderOfInheritance: StoryMediaPlatform[];
	currState: BBStates;
}) => {
	value: string | number | boolean | object;
	inheritance: Partial<Record<string, BBInheritanceValue[] | undefined>> | BBInheritanceValue[] | undefined;
};
