import React from 'react';
import { get } from 'lodash';

import t from 'utils/translate';
import { isObjWithEmptyFields } from 'utils/helpers';
import IntegrationsFacade, {
	INTEGRATIONS_NAMES,
	INTEGRATIONS_PAGE_VIEW,
	INTEGRATIONS_SCOPE,
} from 'utils/facades/integrations-facade';
import Text from 'admin/components/common/Text';
import { Icon } from 'admin/components/common/Icon';

import { useAdminSelector } from 'admin/reducers';
import { selectEditableStoryType } from 'admin/reducers/story-editor/selectors';
import type { IntegrationComponentProps } from '../types';

import css from './Intro.scss';

type Props = IntegrationComponentProps;

export const Intro: React.FC<Props> = props => {
	const storyType = useAdminSelector(selectEditableStoryType);
	const [search, setSearch] = React.useState('');
	const targetIntegrationsList = React.useMemo(
		() =>
			IntegrationsFacade.items.filter(intItem => {
				const name = t(`common.integrations.views.${intItem.translateKey}.title`);
				const exclusionList: INTEGRATIONS_NAMES[] =
					storyType === 'widget'
						? [
								INTEGRATIONS_NAMES.SPREADSHEET_EXPORT,
								INTEGRATIONS_NAMES.WEBHOOKS,
								INTEGRATIONS_NAMES.OUTBRAIN,
								INTEGRATIONS_NAMES.URL_PARAMS,
							]
						: [];

				return (
					intItem.scope.includes(INTEGRATIONS_SCOPE.STORY) &&
					name.toLowerCase().includes(search.toLowerCase()) &&
					!exclusionList.includes(intItem.id)
				);
			}),
		[search, storyType]
	);

	const myIntegrationsList = React.useMemo(
		() =>
			IntegrationsFacade.items.filter(intItem => {
				switch (intItem.id) {
					case INTEGRATIONS_NAMES.WEBHOOKS:
						return props.webhooks.length > 0;
					case INTEGRATIONS_NAMES.SPREADSHEET_IMPORT:
						return props.storySpreadsheets.filter(o => o.type === 'database').length > 0;
					case INTEGRATIONS_NAMES.SPREADSHEET_EXPORT:
						return props.storySpreadsheets.filter(o => o.type === 'export_playthroughs').length > 0;
					default:
						return !isObjWithEmptyFields(get(props.story, intItem.path(props.version)));
				}
			}),
		[props.version, props.story, props.webhooks, props.storySpreadsheets]
	);

	const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
	};

	const onIntegrationItemClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		const { view } = e.currentTarget.dataset;

		props.onChangeView(view as INTEGRATIONS_PAGE_VIEW);
	};

	const renderItemData = ({ icon, translateKey }: ArrayType<typeof myIntegrationsList>) => (
		<>
			<Icon type={icon} width={icon === 'google-sheet-import' || icon === 'google-sheet-export' ? 17 : 22} />
			<Text className={css.text}>{t(`common.integrations.views.${translateKey}.title`)}</Text>
		</>
	);
	const renderMyIntegrations = () => {
		return myIntegrationsList.length ? (
			<div className={css.myIntegrations}>
				<Text className={css.title} weight="bold" tag="div" size={Text.size.subheading}>
					{t(
						`common.integrations.views.${
							IntegrationsFacade.titles[INTEGRATIONS_PAGE_VIEW.DEFAULT]
						}.myIntegrations`
					)}
				</Text>

				<div className={css.list}>
					{myIntegrationsList.map(item => (
						<div key={item.translateKey} className={css.listItem}>
							{renderItemData(item)}
							<button
								className={css.editBtn}
								data-view={item.viewId}
								type="button"
								onClick={onIntegrationItemClick}
							>
								<Icon type="edit" color="white" width={20} />
							</button>
						</div>
					))}
				</div>
			</div>
		) : null;
	};

	const renderAddIntegrations = () => {
		return (
			<div className={css.addIntegration}>
				<div className={css.search}>
					<input
						type="text"
						value={search}
						placeholder={t(
							`common.integrations.views.${
								IntegrationsFacade.titles[INTEGRATIONS_PAGE_VIEW.DEFAULT]
							}.searchPlaceholder`
						)}
						onChange={onSearch}
					/>
					<Icon className={css.searchIcon} type="search" width={24} color="white" />
				</div>
				<div className={css.list}>
					{targetIntegrationsList.length !== 0 ? (
						targetIntegrationsList.map(item => (
							<button
								key={item.translateKey}
								data-view={item.viewId}
								className={css.listItem}
								type="button"
								onClick={onIntegrationItemClick}
							>
								{renderItemData(item)}
							</button>
						))
					) : (
						<Text size="description" className={css.noItems}>
							{t(
								`common.integrations.views.${
									IntegrationsFacade.titles[INTEGRATIONS_PAGE_VIEW.DEFAULT]
								}.noItems`
							)}
						</Text>
					)}
				</div>
			</div>
		);
	};

	return (
		<div className={css.intro}>
			{renderAddIntegrations()}
			{renderMyIntegrations()}
		</div>
	);
};
