import { get, words, capitalize, map } from 'lodash';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import type { PopoverProps } from 'antd';
import cn from 'classnames';
import type { StoryMediaPlatform } from 'types/story';
import Tooltip from 'admin/components/common/Tooltip';
import { COMPONENT_STATES, DEFAULT_MEDIA_QUERY_PLATFORMS } from 'common/constants';
import { CardEditorContext } from 'admin/components/pages/Story/CardEditor/CardEditorContext';
import { MediaQueryIcon } from 'admin/components/common/MediaQueryIcon';
import { selectEditableElement } from 'admin/reducers/card-editor-extra/selectors';
import { selectCurrentPlatform, selectCurrentState } from 'admin/reducers/card-editor/selectors';
import { getStateLabel } from 'admin/components/pages/Story/CardEditor/Inspector/StateSettings';
import { getInheritanceInfo } from 'admin/components/pages/Story/CardEditor/Inspector/PropField';
import { Button } from 'admin/components/pages/Story/CardEditor/Inspector/PropField/Button';
import Text from 'admin/components/common/Text';
import type { FieldConfig } from '../types';
import css from './InspectorToolbar.scss';

const Content = (props: { field: FieldConfig; defaultPlatform: StoryMediaPlatform }) => {
	const currentMQ = useSelector(selectCurrentPlatform);
	const currentState = useSelector(selectCurrentState);
	const element = useSelector(selectEditableElement);

	if (!props.field) {
		return null;
	}

	const inheritanceInfo = getInheritanceInfo({
		inheritance: props.field.inheritance,
		currentMediaQuery: currentMQ,
		currentState,
		propertyName: props.field.name,
	});

	const state = get(props.field, 'inheritance.state', COMPONENT_STATES.DEFAULT);
	const mq = get(props.field, 'inheritance.mq', props.defaultPlatform);
	const hasInheritance = props.field?.inheritance !== undefined;
	const isInheritedState = hasInheritance && state !== currentState;
	const isInheritedMq = hasInheritance && mq !== currentMQ;

	const isAssigned = inheritanceInfo.isUniformProp
		? get(element, props.field.valuePath) !== undefined
		: inheritanceInfo.isAssigned;

	return (
		<div className={css.labelTooltipContent}>
			{isAssigned && (
				<div className={css.labelTooltipTitle}>
					<Button
						preset="reset"
						title="Reset value, assigned for a current platform and state."
						className={css.reset}
						onClick={() => {
							const field = document.querySelector(`input[type="hidden"][name="${props.field.path}"]`);
							if (field) (field as HTMLInputElement).click();
						}}
					>
						reset
					</Button>
				</div>
			)}
			<Text size="footnote" className={css.labelTooltipInfo}>
				<div className={cn(css.row, { [css.inherited]: isInheritedMq })} style={{ marginBottom: 5 }}>
					Platform:
					{inheritanceInfo.isUniformProp ? (
						<span>All</span>
					) : (
						<MediaQueryIcon
							width={18}
							className={css.icon}
							platform={mq}
							defaultPlatform={props.defaultPlatform}
						/>
					)}
				</div>
				<div className={cn(css.row, { [css.inherited]: isInheritedState })}>
					State:
					<span style={{ textTransform: 'capitalize' }}>
						{inheritanceInfo.isUniformProp ? 'All' : getStateLabel(state)}
					</span>
				</div>
			</Text>
		</div>
	);
};

type LabelWithTooltipProps = Partial<PopoverProps> & {
	className?: string;
	field: FieldConfig;
	children?: React.ReactNode;
};

const tooltipProps = {
	align: { offset: [2, 0] },
	placement: 'leftTop' as const,
	stylePreset: 'dark-2' as const,
	overlayInnerStyle: { width: 168 },
	mouseEnterDelay: 0.5,
	overlayClassName: css.labelTooltip,
};

const LabelWithTooltip = ({ field, children, className, ...popoverProps }: LabelWithTooltipProps) => {
	const { mediaQuery } = useContext(CardEditorContext);

	return (
		<Tooltip
			{...tooltipProps}
			className={cn(className, css.labelTooltipRoot)}
			content={
				<Content
					field={field}
					defaultPlatform={mediaQuery?.defaultPlatform || DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP}
				/>
			}
			{...popoverProps}
			trigger={popoverProps.trigger ? popoverProps.trigger : LabelWithTooltip.trigger.default}
		>
			{children || map(words(field.name), v => capitalize(v)).join(' ')}
		</Tooltip>
	);
};

const trigger: Record<string, PopoverProps['trigger']> = {
	default: ['contextMenu', 'hover'],
	contextMenu: ['contextMenu'],
};

LabelWithTooltip.trigger = trigger;

export { LabelWithTooltip };
