/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MODAL } from 'admin/constants/common';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import { ModalReducerState } from 'admin/reducers/modal-reducer';

import { setModal } from 'admin/actions';
import InfoModal from 'admin/components/common/Modal/InfoModal';
import MoveStoryModal from 'admin/components/pages/Stories/MoveStoryModal';
import EditCardModal from 'admin/components/pages/Story/Flow/EditCardModal';
import SwitchTeamModal from 'src/routes/admin/components/pages/Stories/SwitchTeamModal';
import KickOutInfoModal from 'admin/components/common/Modal/KickOutInfoModal';
import CreateStoryModal from 'admin/components/pages/Stories/CreateStoryModal';
import StoryPublishModal from 'admin/components/pages/Story/StoryPublish/Modal';
import CreateCardModal from 'admin/components/pages/Story/Flow/CreateCardModal';
import CreateNavigationModal from 'admin/components/pages/Story/Flow/CreateNavigationModal';
import DeleteStoryModal from 'admin/components/pages/Stories/DeleteStoryModal';
import AddFontsModal from 'admin/components/pages/Settings/Fonts/AddFontsModal';
import DeleteCardModal from 'admin/components/pages/Story/Flow/DeleteCardModal';
import RemoveTeamModal from 'admin/components/pages/Settings/Team/RemoveTeamModal';
import RenameTeamModal from 'admin/components/pages/Settings/Team/RenameTeamModal';
import StorySettingsModal from 'admin/components/pages/Story/Flow/StorySettingsModal';
import DeleteUserAccountModal from 'admin/components/pages/Profile/DeleteAccountModal';
import ChangeUserEmailModal from 'admin/components/pages/Profile/ChangeUserEmailModal';
import ChangeUserPasswordModal from 'admin/components/pages/Profile/ChangeUserPasswordModal';
import EditIntegrationModal from 'admin/components/pages/Settings/Integration/EditIntegrationModal';
import PropSettingsModal from 'admin/components/pages/Story/CardEditor/Inspector/PropSettingsModal';
import EditorsConflictModal from 'admin/components/common/StoryCoEditingObserver/EditorsConflictModal';
import EditorAIModal from 'admin/components/pages/Story/CardEditor/Inspector/EditorAISettings/EditorAIModal';
import CmsPropBindModal from 'admin/components/pages/Story/CardEditor/Inspector/CmsSettings/CmsPropBindModal';
import EditSelectOptionsModal from 'admin/components/pages/Story/CardEditor/Inspector/FieldSettings/EditSelectOptions/Modal';
import CardSettingsModal from 'admin/components/pages/Story/CardEditor/Inspector/CardSettings/CardSettingsModal';
import RemoveMemberFromWorkspaceModal from 'admin/components/pages/Settings/MembersComponent/MemberPopUp/RemoveMemberFromWorkspaceModal';
import EditOverlayTriggerModal from 'admin/components/pages/Story/CardEditor/Inspector/OverlaySettings/EditOverlayTrigger';
import EditSortableBoxOrderModal from 'admin/components/pages/Story/CardEditor/Inspector/BoxSettings/EditOrder/Modal';
import MobileAppAuthenticatorModal from 'admin/components/pages/Profile/MobileAppAuthenticatorModal';
import MobileAppAuthenticatorBackupCodesModal from 'admin/components/pages/Profile/MobileAppAuthenticatorBackupCodesModal';
import MobileAppAuthenticatorRemovalModal from 'admin/components/pages/Profile/MobileAppAuthenticatorRemovalModal';
import WebhookLogsModal from 'admin/components/pages/Story/Flow/StorySettingsModal/Integrations/Webhooks/WebhookLogs';

// Properties provided by ModalManager to current Modal
export type ModalManagerProvidedProps<ID extends MODAL> = {
	id: ID;
	open: boolean;
	close: () => void;
} & ModalReducerState<ID>;

const ModalManager: React.FC = () => {
	const dispatch = useAdminDispatch();
	const { key: location } = useLocation();
	const close = useCallback(() => {
		dispatch(setModal());
	}, [dispatch]);
	const { id, data, autofocus } = useAdminSelector(state => state.modal);
	const [open, setOpen] = useState(false);
	const focusTimeoutRef = useRef<NodeJS.Timeout>();
	const props: ModalManagerProvidedProps<typeof id> = {
		id,
		data,
		autofocus,
		open,
		close,
	};

	useEffect(() => {
		close();
	}, [location, close]);

	useEffect(() => {
		setOpen(id !== MODAL.NONE); // set 'open' in 2nd render to animate Modal properly
	}, [id]);

	useEffect(() => {
		if (autofocus) {
			focusTimeoutRef.current = setTimeout(() => {
				if (open) {
					const input = document.querySelector<HTMLInputElement>(
						'.ant-modal-root input[type=text], .ant-modal-root textarea'
					);

					input?.focus();
				}
			}, 150);

			return () => {
				clearTimeout(focusTimeoutRef.current);
			};
		}
		return () => null;
	}, [open, autofocus]);

	let Modal: React.ComponentType<any> | null;

	switch (props.id as MODAL) {
		case MODAL.STORY_SETTINGS:
			Modal = StorySettingsModal;
			break;
		case MODAL.STORY_PUBLISH:
			Modal = StoryPublishModal;
			break;
		case MODAL.CREATE_STORY:
			Modal = CreateStoryModal;
			break;
		case MODAL.DELETE_STORY:
			Modal = DeleteStoryModal;
			break;
		case MODAL.CREATE_CARD:
			Modal = CreateCardModal;
			break;
		case MODAL.EDIT_CARD:
			Modal = EditCardModal;
			break;
		case MODAL.DELETE_CARD:
			Modal = DeleteCardModal;
			break;
		case MODAL.CREATE_NAVIGATION_CARD:
			Modal = CreateNavigationModal;
			break;
		case MODAL.DELETE_TEAM:
			Modal = RemoveTeamModal;
			break;
		case MODAL.RENAME_TEAM:
			Modal = RenameTeamModal;
			break;
		case MODAL.REMOVE_MEMBER_FROM_WORKSPACE:
			Modal = RemoveMemberFromWorkspaceModal;
			break;
		case MODAL.DELETE_USER_ACCOUNT:
			Modal = DeleteUserAccountModal;
			break;
		case MODAL.CHANGE_USER_EMAIL:
			Modal = ChangeUserEmailModal;
			break;
		case MODAL.CHANGE_USER_PASSWORD:
			Modal = ChangeUserPasswordModal;
			break;
		case MODAL.INFORMATION:
			Modal = InfoModal;
			break;
		case MODAL.STORY_EDITORS_CONFLICT:
			Modal = EditorsConflictModal;
			break;
		case MODAL.KICK_OUT_INFO_MODAL:
			Modal = KickOutInfoModal;
			break;
		case MODAL.PROP_SETTINGS:
			Modal = PropSettingsModal;
			break;
		case MODAL.EDIT_INTEGRATION:
			Modal = EditIntegrationModal;
			break;
		case MODAL.MOVE_STORY:
			Modal = MoveStoryModal;
			break;
		case MODAL.SWITCH_TEAM:
			Modal = SwitchTeamModal;
			break;
		case MODAL.ADD_FONTS:
			Modal = AddFontsModal;
			break;
		case MODAL.CARD_SETTINGS:
			Modal = CardSettingsModal;
			break;
		case MODAL.EDITOR_AI:
			Modal = EditorAIModal;
			break;
		case MODAL.CMS_PROP_BIND_MODAL:
			Modal = CmsPropBindModal;
			break;
		case MODAL.EDIT_SELECT_OPTIONS:
			Modal = EditSelectOptionsModal;
			break;
		case MODAL.EDIT_OVERLAY_TRIGGERS:
			Modal = EditOverlayTriggerModal;
			break;
		case MODAL.EDIT_SORTABLE_BOX_ORDER:
			Modal = EditSortableBoxOrderModal;
			break;
		case MODAL.TWO_FA:
			Modal = MobileAppAuthenticatorModal;
			break;
		case MODAL.TWO_FA_BACKUP_CODES:
			Modal = MobileAppAuthenticatorBackupCodesModal;
			break;
		case MODAL.TWO_FA_REMOVAL:
			Modal = MobileAppAuthenticatorRemovalModal;
			break;
		case MODAL.WEBHOOK_LOGS:
			Modal = WebhookLogsModal;
			break;
		default:
			Modal = null;
			break;
	}

	return Modal ? <Modal {...props} /> : null;
};

export default ModalManager;
