import _ from 'lodash';

export function formatNumber(num: number): string {
	const pointsMap = {
		K: 1000,
		M: 1000000,
	};
	let result = num.toString();

	_.forEach(pointsMap, (val, key) => {
		if (num / val >= 1) {
			result =
				(parseFloat((num / val).toFixed(1)) * 10) % 10 === 0
					? `${(num / val).toFixed(0)}${key}`
					: `${(num / val).toFixed(1)}${key}`;
		}
	});

	return result;
}
