import { DataType, FieldType } from 'admin/components/pages/SuperUser/Playground/Table/utils';

/*
 Implementation concept:

 [Editor]      : connect elements with cms. create references and mark repeatable components
                 display cms data or no?
                 currently displayed data for all particular fields and for repeatable only 1st item

 				 *Required data*: on demand for a sidebar or all integrated collections in case of we display cms data

 [Preview]     : process story in place where data is loaded and modify to generate repeatable components

 				 *Required data*: all integrated collections

 [Pre-publish] : process story in place where data is pushed to server and modify story settings with
                 repeatable components data. Don't need to store generated blocks!
                 Need only to store in settings generated ids for repeatable components
                 and original ids for elements which were used to generate repeatable components. For example:
                 `answers: { [generatedBlockId]: { generatedFor: { dataId, originalBlockId } } }`

 				 *Required data*: length of each collection, to loop through repeatable components, but if there is no
 				 repeatable, then I don't need anything

 [Published]   : process story in place where data is loaded and modify to generate repeatable components,
                 but reuse generated ids from pre-publish if they exist

 				 *Required data*: all integrated collections, to generate repeatable components and display actual data
 */

const fields1 = [
	{
		title: 'Color',
		dataIndex: 'color',
		fieldType: FieldType.Text,
	},
	{
		title: 'Title',
		dataIndex: 'title',
		fieldType: FieldType.TextMultiline,
	},
	{
		title: 'Background Image',
		dataIndex: 'backgroundImage',
		fieldType: FieldType.Text,
	},
];

const imageHosting = 'https://dev-storycards.imgix.net/';
const images = [
	`${imageHosting}2024/09/17/1726563426469-ce0d3061-baf3-45f8-a01f-0367c6ad4bd0.jpg`,
	`${imageHosting}2024/09/17/1726563446392-c582df77-7823-40ec-b532-77625cbe4b58.jpg`,
	`${imageHosting}2024/09/17/1726563498715-63a01f6f-b32f-4ae5-81f5-358dc9f2381a.jpg`,
	`${imageHosting}2024/09/17/1726563520742-b6ca7728-a0f1-48e2-849f-53ab74251c76.jpg`,
	`${imageHosting}2024/09/17/1726563536416-991bfb9e-80f5-459d-a08c-019602b375da.jpg`,
	`${imageHosting}2024/09/17/1726563558316-22c167c2-825b-44ce-a223-e0f4c6eb6a73.jpg`,
	`${imageHosting}2024/09/17/1726563574646-d36b3342-bf43-4fb4-8b2e-f05fa77eef60.jpg`,
	`${imageHosting}2024/09/17/1726563590830-8eb3735e-1af7-49e2-a55c-1fa1531e9b39.jpg`,
	`${imageHosting}2024/09/17/1726563604922-d273993e-ea03-4992-8d91-7f1256ff040f.jpg`,
	`${imageHosting}2024/09/17/1726563622216-ad2b7786-8c66-4c3f-9f19-7a083d95efa7.jpg`,

	`${imageHosting}2024/09/17/4321d98d-2a5d-4eba-8c3b-266c5d9c3708.jpeg`,
	`${imageHosting}2023/10/15/1697373315337-4619d02a-e8c2-446b-ae67-a5cf9e16bf6c.jpg?oW=1024&oH=1024`,
	`${imageHosting}2023/10/15/1697373884085-6abed7c7-0e02-4965-ace2-0d51b2977e95.jpg?oW=1024&oH=1024`,
	`${imageHosting}2023/10/15/1697373398859-5ee32d47-6ebb-4233-aafe-9fc4d287f0ad.jpg?oW=1024&oH=1024`,
	`${imageHosting}2023/10/15/1697373433455-63fff680-7925-4005-b54b-cf587622920f.jpg?oW=1024&oH=1024`,
	`${imageHosting}2023/10/15/1697373556996-1be6df83-773e-4ab8-9c7d-b13d71ea82bb.jpg?oW=1024&oH=1024`,
	`${imageHosting}2023/10/15/1697373615796-03c7f7b1-97dc-48e3-af21-4793304e2394.jpg?oW=1024&oH=1024`,
	`${imageHosting}2023/10/15/1697373663038-8e863a09-891c-4f7a-a502-a99adb6137b9.jpg?oW=1024&oH=1024`,
];

const data: DataType[] = [
	{
		key: '1',
		title:
			'This example <b>includes:</b>' +
			'<br/> 1) common: create mock <u>collection</u>' +
			'<br/> 2) admin: manual replacement values in story.json with a references to mock collection.' +
			'<br/> 3) admin: mark in sidebar property that comes from collection .' +
			'<br/> 4) client: mark in preview text that comes from collection .' +
			'<br/> 4) client: replace text reference with a value from cms.' +
			'<br/> 5) client|admin: replace style reference with a value from cms.',
		color: '#ed143d',
		backgroundImage: images[0],
	},
	{
		key: '2',
		title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
		color: '#9932cc',
	},
];

const fields2 = [
	{
		title: 'Name',
		dataIndex: 'name',
		fieldType: FieldType.Text,
	},
	{
		title: 'Age',
		dataIndex: 'age',
		fieldType: FieldType.Number,
	},
	{
		title: 'Bio',
		dataIndex: 'bio',
		fieldType: FieldType.TextMultiline,
	},
	{
		title: 'Color',
		dataIndex: 'color',
		fieldType: FieldType.Text,
	},
	{
		title: 'Background Image',
		dataIndex: 'backgroundImage',
		fieldType: FieldType.Text,
	},
];

const data2: DataType[] = [
	{ key: '1', name: 'Harry Potter', age: 17, bio: 'The Boy Who Lived, master of the Elder Wand.' },
	{ key: '2', name: 'Hermione Granger', age: 18, bio: 'Brightest witch of her age, loyal to her friends.' },
	{ key: '3', name: 'Frodo Baggins', age: 50, bio: 'Hobbit who carried the One Ring to Mount Doom.' },
	{ key: '4', name: 'Tony Stark', age: 48, bio: 'Genius, billionaire, and armored Avenger.' },
	{ key: '5', name: 'Katniss Everdeen', age: 16, bio: 'The Girl on Fire, symbol of rebellion in Panem.' },
	{ key: '6', name: 'Bruce Wayne', age: 35, bio: "Gotham's dark knight, fighting crime as Batman." },
	{ key: '7', name: 'Diana Prince', age: 3000, bio: 'Amazonian princess and fierce warrior, Wonder Woman.' },
	{ key: '8', name: 'Luke Skywalker', age: 23, bio: 'Jedi Knight who brought balance to the Force.' },
	{ key: '9', name: 'Sherlock Holmes', age: 40, bio: 'Brilliant detective with unmatched deductive skills.' },
	{ key: '10', name: 'Lara Croft', age: 29, bio: 'Adventurous archaeologist with a knack for danger.' },
].map((o, i) => ({
	...o,
	backgroundImage: images[i] ?? images[i % images.length],
	color: `rgba(${200 - i * 10}, ${200 - i * 10}, ${200 - i * 10}, 1)`,
}));

const collections = [
	{ collectionId: 'storycards', name: 'Collection 1', fields: fields1, data },
	{ collectionId: 'repeat', name: 'Collection 2', fields: fields2, data: data2 },
];

const DATA_ANY = 'any';
const DELIMITER = '::';
const referencePattern = new RegExp(`^${DELIMITER}([^: ]+)${DELIMITER}([^: ]+)${DELIMITER}([^: ]+)$`);

const createReference = (collectionId: string, dataId: string, fieldName: string) => {
	return `${DELIMITER}${collectionId}${DELIMITER}${dataId}${DELIMITER}${fieldName}`;
};

const isCollectionReference = (value: unknown): value is string => {
	return typeof value === 'string' && value.match(referencePattern) !== null;
};

const parseReference = (value: string, logError = true) => {
	if (typeof value !== 'string') {
		if (logError) console.error('Invalid reference format');
		return null;
	}
	const match = value.match(referencePattern);

	if (!match) {
		if (logError) console.error('Invalid reference format');
		return null;
	}

	const [, collectionId, dataId, fieldName] = match;

	return {
		collectionId,
		dataId,
		fieldName,
	};
};

const replaceCollectionReference = (value: string) => {
	const parsedReference = parseReference(value);
	if (!parsedReference) {
		return value;
	}
	const { collectionId, dataId, fieldName } = parsedReference;
	const collection = collections.find(o => collectionId === o.collectionId);
	const dataRow = collection?.data.find((o, i) => (dataId === DATA_ANY ? i === 0 : o.key === dataId));
	return dataRow && fieldName in dataRow ? dataRow[fieldName] : value;
};

export default {
	collections,
	parseReference,
	isCollectionReference,
	replaceCollectionReference,
	createReference,
	DATA_ANY,
};
