import React from 'react';
import { Field } from 'redux-form';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Grid, Column } from 'admin/components/common/Grid';
import { ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { TextField } from 'admin/components/common/Form';
import { CARD_NAME_MAX_LEN } from 'admin/components/common/Form/validations';
import t from 'utils/translate';
import { Context, THEME } from 'admin/components/common/Theme';
import CharacterEvents from './CharacterEvents';
import ScoreEvents from './ScoreEvents';
import css from './EditCardModal.scss';
import { fields, ChildrenFormProp } from './Form';

export enum EditCardModalType {
	editScoreNav = 'editScoreNav',
	editCharacterNav = 'editCharacterNav',
	default = 'default',
}

type Props = {
	editType: EditCardModalType;
	onDiscard?: () => void;
	form: ChildrenFormProp;
};

const Content: React.FC<Props> = ({ editType, onDiscard, form: { invalid, formData, change } }) => {
	const context = React.useContext(Context);
	const columnProps: { colStart: '1' | '3'; colSpan: '8' | '12' } =
		context?.theme === THEME.DARK ? { colStart: '1', colSpan: '12' } : { colStart: '3', colSpan: '8' };

	return (
		<>
			<ModalBody className={css.body}>
				<Grid columns="12" columnGap={Grid.gap.small} rowGap={Grid.gap.medium} className={css.bodyGrid}>
					{editType === EditCardModalType.default ? (
						<Column {...columnProps}>
							<Field
								name={fields.name}
								component={TextField}
								label={t(`story.editCardModal.fields.${fields.name}.label`)}
								limit={{ max: CARD_NAME_MAX_LEN }}
								theme={context?.theme}
								isLabelUppercase={false}
								isRequired
							/>
							<Text
								className={css.fieldDescription}
								text={t(`story.editCardModal.fields.${fields.name}.hint`)}
								size={Text.size.description}
							/>
						</Column>
					) : editType === EditCardModalType.editScoreNav ? (
						<Column {...columnProps}>
							<ScoreEvents events={formData.events} change={change} />
						</Column>
					) : editType === EditCardModalType.editCharacterNav ? (
						<Column {...columnProps}>
							<CharacterEvents events={formData.events} change={change} />
						</Column>
					) : null}
				</Grid>
			</ModalBody>
			<ModalFooter>
				<Column justifyContent="flex-start">
					<Button type="submit" theme={context?.theme} view="primary" smallText disabled={invalid}>
						{t('story.editCardModal.save')}
					</Button>
					<Button theme={context?.theme} view="empty" smallText onClick={onDiscard}>
						{t('story.editCardModal.discard')}
					</Button>
				</Column>
			</ModalFooter>
		</>
	);
};

export { Content };
