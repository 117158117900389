import React from 'react';
import cn from 'classnames';
import Text from 'admin/components/common/Text';
import css from './Hint.scss';

type Props = {
	className?: string;
	theme?: 'dark' | 'light';
	text: string | React.ReactNode;
	weight?: (typeof Text.weight)[keyof typeof Text.weight];
	tag?: string;
};

export const Hint: React.FC<Props> = ({ text, theme = 'light', weight, className = '', ...props }) => {
	return (
		<Text {...props} weight={weight} size="description" className={cn(css.hint, css[theme], className)}>
			{text}
		</Text>
	);
};
