import { addBreadcrumb, captureMessage } from '@sentry/react';
import { find, get, max, multiply, round, omit, sum } from 'lodash';
import { CardData, StorySettingsType } from 'types/story';
import { SCORE } from 'common/constants';
import { CountType } from 'utils/facades/navigation-card-facade';
import { SetFormDataPayload } from 'client/actions/set-form-data';
import { CardFacade } from 'utils/facades/card-facade';
import { clientLog, int } from 'utils/helpers';
import { lsNames } from 'client/constants/common';

const log = clientLog.extend('StoryHistory');

type StoryId = string;
type CardId = string;
type CardHistory = {
	answers?: Array<{ id: string; isCorrectOrder?: boolean }>;
	formData?: SetFormDataPayload['data'];
};
type StoryHistoryItemType = {
	// current play(cp). temporary. initialized on every page load.
	cp?: { cards: Record<CardId, CardHistory> };
	// last visited card(lvc). permanent. do not delete it.
	lvc?: CardId;
};

type UserHistory = Record<StoryId, StoryHistoryItemType>;

export class StoryHistory {
	storyId: StoryId;

	itemName = lsNames.history;

	constructor({ storyId = '' }) {
		if (!storyId) {
			captureMessage('StoryHistory.constructor: invalid "storyId"', {
				level: 'error',
				contexts: { data: { storyId } },
			});
		}

		this.storyId = storyId;
	}

	get history(): UserHistory {
		const item = localStorage.getItem(this.itemName);
		const userHistory: UserHistory = item ? JSON.parse(item) : {};

		addBreadcrumb({
			type: 'debug',
			category: 'StoryHistory',
			message: 'getter history',
			level: 'debug',
			data: { storyId: this.storyId, history: JSON.stringify(userHistory[this.storyId]) ?? 'no_history' },
		});

		const storyHistoryStored: Partial<StoryHistoryItemType> = userHistory[this.storyId] ?? {};
		const storyHistory: StoryHistoryItemType = {
			...storyHistoryStored,
			cp: { ...storyHistoryStored.cp, cards: { ...storyHistoryStored.cp?.cards } },
		};

		return {
			...userHistory,
			[this.storyId]: storyHistory,
		};
	}

	get lastVisitedCard(): string | undefined {
		const story = this.history[this.storyId];
		return story.lvc ?? (story as any).cardId /* right operand is an old syntax <= release v1.11.7 */;
	}

	get cardsPlayed() {
		return Object.entries(this.history[this.storyId].cp?.cards ?? {});
	}

	isCardVisited({ cardId }: { cardId: string }) {
		return this.history[this.storyId].cp?.cards[cardId] !== undefined;
	}

	clearLastPlay() {
		addBreadcrumb({
			type: 'debug',
			category: 'StoryHistory',
			message: 'clearLastPlay',
			level: 'debug',
			data: { storyId: this.storyId },
		});

		try {
			const nextHistory: UserHistory = {};
			Object.entries(this.history).forEach(([id, data]) => {
				if (id === this.storyId) {
					// initialize current story history with a default values
					nextHistory[id] = { ...data, cp: { ...data.cp, cards: {} } };
				} else {
					// clear current play history for the rest stories
					nextHistory[id] = omit(data, ['cp']);
				}
			});
			localStorage.setItem(this.itemName, JSON.stringify(nextHistory));
		} catch (e) {
			captureMessage(`StoryHistory.clearLastPlay: ${(e as Error).message}`, {
				level: 'error',
				contexts: { data: { storyId: this.storyId } },
			});
		}
	}

	addCard({ cardId }: { cardId: string }) {
		addBreadcrumb({
			type: 'debug',
			category: 'StoryHistory',
			message: 'addCard.start',
			level: 'debug',
			data: { storyId: this.storyId, cardId: cardId || 'no_card_id' },
		});

		try {
			const userHistory = this.history;
			const currentStoryHistory = userHistory[this.storyId];
			const nextUserHistory: UserHistory = {
				...userHistory,
				[this.storyId]: {
					...currentStoryHistory,
					lvc: cardId,
					cp: {
						...currentStoryHistory.cp,
						cards: {
							...currentStoryHistory.cp?.cards,
							[cardId]: {},
						},
					},
				},
			};
			addBreadcrumb({
				type: 'debug',
				category: 'StoryHistory',
				message: 'addCard.setItem',
				level: 'debug',
				data: { nextStoryHistory: JSON.stringify(nextUserHistory[this.storyId]) },
			});
			localStorage.setItem(this.itemName, JSON.stringify(nextUserHistory));
		} catch (e) {
			captureMessage(`StoryHistory.addCard: ${(e as Error).message}`, {
				level: 'error',
				contexts: { data: { storyId: this.storyId, cardId } },
			});
		}
	}

	addAnswer({ selectedAnswers, cardId }: { selectedAnswers: NonNullable<CardHistory['answers']>; cardId: string }) {
		addBreadcrumb({
			type: 'debug',
			category: 'StoryHistory',
			message: 'addAnswer',
			level: 'debug',
			data: { storyId: this.storyId, cardId, selectedAnswers },
		});

		const userHistory = this.history;
		const currentStoryHistory = userHistory[this.storyId];

		try {
			if (!currentStoryHistory.cp || !currentStoryHistory.cp.cards?.[cardId]) {
				captureMessage('StoryHistory.addAnswer: current play data is missing', {
					level: 'error',
					contexts: { data: { storyId: this.storyId, cardId, history: JSON.stringify(currentStoryHistory) } },
				});
			}

			const nextUserHistory: UserHistory = {
				...userHistory,
				[this.storyId]: {
					...currentStoryHistory,
					cp: {
						...currentStoryHistory.cp,
						cards: {
							...currentStoryHistory.cp?.cards,
							[cardId]: {
								...currentStoryHistory.cp?.cards?.[cardId],
								answers: selectedAnswers,
							},
						},
					},
				},
			};
			localStorage.setItem(this.itemName, JSON.stringify(nextUserHistory));
		} catch (e) {
			captureMessage(`StoryHistory.addAnswer: ${(e as Error).message}`, {
				level: 'error',
				contexts: { data: { storyId: this.storyId, cardId, history: JSON.stringify(currentStoryHistory) } },
			});
		}
	}

	addFormData({ cardId, data }: { cardId: string; data: SetFormDataPayload['data'] }) {
		addBreadcrumb({
			type: 'debug',
			category: 'StoryHistory',
			message: 'addFormData',
			level: 'debug',
			data: { storyId: this.storyId, cardId, data: JSON.stringify(data) },
		});

		const userHistory = this.history;
		const currentStoryHistory = userHistory[this.storyId];

		try {
			if (!currentStoryHistory.cp || !currentStoryHistory.cp.cards?.[cardId]) {
				captureMessage('StoryHistory.addFormData: current play data is missing', {
					level: 'error',
					contexts: { data: { storyId: this.storyId, cardId, history: JSON.stringify(currentStoryHistory) } },
				});
			}

			const nextUserHistory: UserHistory = {
				...userHistory,
				[this.storyId]: {
					...currentStoryHistory,
					cp: {
						...currentStoryHistory.cp,
						cards: {
							...currentStoryHistory.cp?.cards,
							[cardId]: {
								...currentStoryHistory.cp?.cards?.[cardId],
								formData: data,
							},
						},
					},
				},
			};
			localStorage.setItem(this.itemName, JSON.stringify(nextUserHistory));
		} catch (e) {
			captureMessage(`StoryHistory.addFormData: ${(e as Error).message}`, {
				level: 'error',
				contexts: { data: { storyId: this.storyId, cardId, history: JSON.stringify(currentStoryHistory) } },
			});
		}
	}

	get submittedFormData() {
		return Object.entries(this.history[this.storyId].cp?.cards ?? {}).reduce(
			(acc, [cardId, value]) => {
				if (value.formData) acc[cardId] = value.formData;
				return acc;
			},
			{} as Record<SetFormDataPayload['cardId'], SetFormDataPayload['data']>
		);
	}

	/**
	 * Get total score for played cards
	 */
	totalScore(storySettings: StorySettingsType, cards: CardData[]) {
		const result = {
			// Maximum score - the sum of the maximum score for the cards played
			scoreMax: 0,
			// Total score - the amount of score received for the cards played
			scoreTotal: 0,
			// Relative score - the ratio of the total score to the maximum (0 - 100)
			scoreRelative: 0,
			// total score received for answers per card
			scorePerCard: {} as { [cardId: string]: number },
			// total completed cards that can have a score
			cardsScored: 0,
			// total completed cards that can have an answer
			cardsAnswered: 0,
			// total correct/incorrect answers sent
			answersCorrectIncorrect: 0,
			// total correct answers sent
			answersCorrect: 0,
			// total selected answers in story
			totalAnswersSelected: 0,
		};

		this.cardsPlayed.forEach(([cardId, { answers: selectedAnswers = [] }]) => {
			const { score, answers = {}, countType } = storySettings.cards?.[cardId] ?? {};
			const cardData = find(cards, ['_id', cardId]);
			const canCardScore = cardData ? CardFacade.hasFeature(cardData.type).score : false;
			const canAnswerCorrect = cardData ? CardFacade.hasFeature(cardData.type).correct : false;
			const canAnswer = cardData ? CardFacade.hasFeature(cardData.type).answer : false;
			const canAnswerScore = cardData && CardFacade.hasFeature(cardData.type).answerScore;

			const isSortableTrivia = cardData?.type === 'SORTABLE_TRIVIA';
			const isPersonalityTest = cardData?.type === 'PERSONALITY_TEST';

			// count number of answers selected in story
			result.totalAnswersSelected += selectedAnswers.length ?? 0;

			/* count answers. each visited card that can score points must increase the "cardsScored" value.
			 If the user did not answer, it will be considered that he answered incorrectly. */
			if (canCardScore) result.cardsScored += 1;

			if (canAnswer) result.cardsAnswered += 1;

			// character count type cards cannot score, they can only give an amount of points to particular characters
			if (countType === CountType.character) return;

			// Calculate score:

			// Get score of card (e.g. Trivia card)
			const defaultCardScore = canCardScore ? SCORE.DEFAULT : 0;
			const cardScore = int(score ?? defaultCardScore);

			// Get max answer score per card (e.g. PersonalityTest card, Sortable Trivia)
			const defaultAnswerScore = canAnswerScore ? SCORE.DEFAULT : 0;
			const answersScore = Object.values(answers).map(answer =>
				int('score' in answer ? answer.score : defaultAnswerScore)
			);

			if (canCardScore) {
				result.scoreMax += cardScore;
			} else if (canAnswerScore) {
				result.scoreMax += isSortableTrivia ? sum(answersScore) : max(answersScore) || 0;
			}

			// sum up the score for chosen answers
			selectedAnswers.forEach((answer: ArrayType<typeof selectedAnswers>) => {
				const answerId = answer.id;

				if (canAnswerCorrect) {
					result.answersCorrectIncorrect += 1;
				}

				// Add card score for a correct answer (e.g. Trivia card)
				const isCorrect = get(answers, `${answerId}.isCorrect`);

				if (canCardScore && isCorrect) {
					const extraScore = isCorrect ? cardScore : 0;
					result.scoreTotal += extraScore;
					result.scorePerCard[cardId] = (result.scorePerCard[cardId] ?? 0) + extraScore;
					result.answersCorrect += 1;
				}

				// Add answer score (PersonalityTest card, SortableTrivia card)
				const answerScore = int(get(answers, [answerId, 'score']) ?? defaultAnswerScore);
				if (answerScore) {
					const isCorrectSortableTrivia = isSortableTrivia && answer?.isCorrectOrder;
					if (isPersonalityTest || isCorrectSortableTrivia) {
						result.scoreTotal += answerScore;
						result.scorePerCard[cardId] = (result.scorePerCard[cardId] ?? 0) + answerScore;
					}
					if (isCorrectSortableTrivia) {
						result.answersCorrect += 1;
					}
				}
			});
		});

		result.scoreRelative = multiply(result.scoreTotal / result.scoreMax || 0, 100);

		log('totalScore', result);

		return result;
	}

	/**
	 * Get total character points for played cards
	 */
	totalCharacterPoints(storySettings: StorySettingsType) {
		const result: { [characterId: string]: number } = {};

		this.cardsPlayed.forEach(([cardId, data]) => {
			const playedCardAnswerSettings = storySettings.cards?.[cardId]?.answers;
			const playedCardChosenAnswers = data.answers;

			if (!playedCardAnswerSettings) return;

			playedCardChosenAnswers?.forEach(({ id: playedAnswerId }) => {
				const answerSettings = playedCardAnswerSettings[playedAnswerId];

				if (answerSettings && 'characterPoints' in answerSettings && answerSettings.characterPoints) {
					Object.entries(answerSettings.characterPoints).forEach(([characterId, characterPoints]) => {
						const characterPointsNumber = parseFloat(characterPoints);

						if (Number.isNaN(characterPointsNumber)) return;

						result[characterId] =
							result[characterId] === undefined
								? characterPointsNumber
								: result[characterId] + characterPointsNumber;
					});
				}
			});
		});

		log('totalCharacterPoints', result);

		return result;
	}
}

export type TotalScoreType = ReturnType<typeof StoryHistory.prototype.totalScore>;

export type TotalCharacterPointsType = ReturnType<typeof StoryHistory.prototype.totalCharacterPoints>;

class CardTimeDelta {
	private delta: Record<string, number> = {};

	private addBreadcrumb(action: string, cardId?: string) {
		addBreadcrumb({
			type: 'debug',
			category: 'CardTimeDelta',
			message: `CardTimeDelta.${action}`,
			level: 'debug',
			data: { cardId, delta: cardId ? this.delta[cardId] : 'No delta' },
		});
	}

	start(cardId: string) {
		this.delta[cardId] = Date.now();

		this.addBreadcrumb('start', cardId);
		log('CardTimeDelta start', this.delta[cardId]);
	}

	end(cardId: string) {
		if (cardId === undefined || !(cardId in this.delta)) {
			captureMessage('CardTimeDelta.end: no delta.', {
				level: 'error',
				contexts: { data: { cardId, history: JSON.stringify(this.delta) } },
			});

			return;
		}

		this.delta[cardId] = round((Date.now() - this.delta[cardId]) / 1000, 3);

		this.addBreadcrumb('end', cardId);
		log('CardTimeDelta end', this.delta[cardId]);
	}

	get(cardId: string) {
		const delta = this.delta[cardId];

		this.addBreadcrumb('get', cardId);

		if (delta < 0) {
			captureMessage('CardTimeDelta.get: delta is < 0.', {
				level: 'error',
				contexts: { data: { delta, cardId, history: JSON.stringify(this.delta) } },
			});
		}

		return delta;
	}
}

export const AnswerDelta = new CardTimeDelta();
export const SubmissionDelta = new CardTimeDelta();
