import React from 'react';
import { ChangeAction, Field } from 'redux-form';
import { map } from 'lodash';
import type { CardFlowEvent } from 'types/story';
import { CardEvent } from 'common/utils/facades/templates/card-event';
import { EVENT_NAME_MAX_LEN } from 'admin/components/common/Form/validations';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { createTemplateName } from 'admin/utils/create-template-name';
import { Context as ThemeContext } from 'admin/components/common/Theme';
import { TextField } from 'admin/components/common/Form';
import { fields } from 'admin/components/pages/Story/Flow/EditCardModal/Form';
import css from './EditCardModal.scss';

type Props = {
	events: CardFlowEvent[];
	change: ChangeAction;
};

const CharacterEvents: React.FC<Props> = props => {
	const context = React.useContext(ThemeContext);

	const addEvent = () => {
		props.change('events', [
			...props.events,
			CardEvent({ name: createTemplateName({ name: 'Character', exclude: map(props.events, o => o.name) }) }),
		]);
	};

	return (
		<div className={css.chars}>
			{props.events.map((event, index) => (
				<Field
					className={css.charEvent}
					key={`char-event-editor-${event._id}`}
					name={fields.eventName(index)}
					component={TextField}
					isRequired
					props={{
						limit: { max: EVENT_NAME_MAX_LEN, position: TextField.limitPosition.in },
						theme: context?.theme,
						isLabelUppercase: false,
						isRequired: true,
					}}
				/>
			))}
			<Button
				type="button"
				view="empty"
				textSize={Text.size.label}
				textWeight={Text.weight.normal}
				onClick={() => addEvent()}
			>
				Add character
			</Button>
		</div>
	);
};

export default CharacterEvents;
