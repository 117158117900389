import type { JSX } from 'react';
import type { TableColumnsType } from 'antd';

export enum FieldType {
	Text = 'text', // consider to add Link settings to make entire text as a link
	TextMultiline = 'textMultiline', // text..
	Number = 'number', // text..
	Boolean = 'boolean', // ??
	Date = 'date', // ??
	URL = 'url', // start with an image and button props
	// imageArray = 'imageArray', // image array of urls to repeat images
	// videoArray = 'videoArray', // image array of urls to repeat images
}

export type ColumnType = TableColumnsType<DataType>[number] & {
	editable?: boolean;
	dataIndex: string;
	fieldType: FieldType;
};

export interface DataType {
	key: string;
	[field: string]: Values;
}

export const parseValue: Record<FieldType, (value: Values) => Values> = {
	[FieldType.Text]: (value: Values) => {
		return value?.toString();
	},
	[FieldType.TextMultiline]: (value: Values) => {
		return value?.toString();
	},
	[FieldType.Number]: (value: Values) => {
		const num = Number(value);
		return Number.isNaN(num) ? undefined : num;
	},
	[FieldType.Boolean]: (value: Values) => {
		return Boolean(value);
	},
	[FieldType.Date]: (value: Values) => {
		const date = new Date(value as string);
		return Number.isNaN(date.getTime()) ? undefined : date.toISOString();
	},
	[FieldType.URL]: (value: Values) => {
		try {
			return new URL(value as string).toString();
		} catch {
			return undefined;
		}
	},
};

type Values = JSX.Element | string | number | boolean | undefined;

type Transformer = (value: Values) => Values;

type TransformMap = {
	[K in FieldType]: Partial<Record<Exclude<FieldType, K>, Transformer>>;
};

const sharedTextTransformers: ValuesType<TransformMap> = {
	[FieldType.Boolean]: parseValue.boolean,
	[FieldType.Number]: parseValue.number,
	[FieldType.Date]: parseValue.date,
	[FieldType.URL]: parseValue.url,
};

const transformers: TransformMap = {
	[FieldType.Text]: { ...sharedTextTransformers, [FieldType.TextMultiline]: parseValue[FieldType.TextMultiline] },
	[FieldType.TextMultiline]: { ...sharedTextTransformers, [FieldType.Text]: parseValue[FieldType.Text] },
	[FieldType.Number]: {
		[FieldType.Text]: parseValue[FieldType.Text],
		[FieldType.TextMultiline]: parseValue[FieldType.TextMultiline],
		[FieldType.Boolean]: parseValue[FieldType.Boolean],
	},
	[FieldType.Boolean]: {
		[FieldType.Text]: parseValue[FieldType.Text],
		[FieldType.TextMultiline]: parseValue[FieldType.TextMultiline],
	},
	[FieldType.Date]: {
		[FieldType.Text]: parseValue[FieldType.Text],
		[FieldType.TextMultiline]: parseValue[FieldType.TextMultiline],
		[FieldType.Number]: parseValue[FieldType.Number],
		[FieldType.Boolean]: parseValue[FieldType.Boolean],
	},
	[FieldType.URL]: {
		[FieldType.Text]: parseValue[FieldType.Text],
		[FieldType.TextMultiline]: parseValue[FieldType.TextMultiline],
		[FieldType.Boolean]: parseValue[FieldType.Boolean],
	},
};

// Main transformation function
// NOTE: This function is not used in the current implementation, it probably should be done on the server side
export const transformValue = (value: Values, fromType: FieldType, toType: FieldType): Values => {
	if (fromType === toType) {
		return value;
	}

	const typeTransformers = transformers[fromType];
	if (typeTransformers && typeTransformers[toType]) {
		return typeTransformers[toType](value);
	}

	return undefined;
};
