import { template, toUpper, trim } from 'lodash';

interface CreateTemplateName {
	name: string;
	exclude: string[];
}

export const createTemplateName = (props: CreateTemplateName) => {
	const symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const pattern = {
		name: props.name,
		symbol: '\\s[A-z]$',
		template({ symbol }: { symbol: string }) {
			return template('<%= name %> <%= symbol %>')({ name: this.name, symbol: toUpper(symbol) });
		},
	};

	const regex = new RegExp(`(${pattern.name})(${pattern.symbol})`);
	const matchList = props.exclude.map(s => s.match(regex) ?? null).filter(Array.isArray) as RegExpMatchArray[];
	const [unusedSymbol = '(new)'] = symbols
		.split('')
		.filter(s => !matchList.some(([value, name, symbol]) => toUpper(trim(symbol)) === s));

	return pattern.template({ symbol: unusedSymbol });
};
